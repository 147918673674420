export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';
export const UPDATE_CATEGORY_DATA = 'UPDATE_CATEGORY_DATA';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';
export const ADD_CATEGORY_IN_PROGRESS = 'ADD_CATEGORY_IN_PROGRESS';
export const UPDATE_CATEGORY_IN_PROGRESS = 'UPDATE_USER_IN_PROGRESS';
export const READ_ALL_CATEGORIES_IN_PROGRESS = 'READ_ALL_CATEGORIES_IN_PROGRESS';
export const READ_ALL_CATEGORIES_SUCCESS = 'READ_ALL_CATEGORIES_SUCCESS';
export const READ_ALL_CATEGORIES_ERROR = 'READ_ALL_CATEGORIES_ERROR';
export const READ_USER_CATEGORIES_IN_PROGRESS = 'READ_USER_CATEGORIES_IN_PROGRESS';
export const READ_USER_CATEGORIES_SUCCESS = 'READ_USER_CATEGORIES_SUCCESS';
export const READ_USER_CATEGORIES_ERROR = 'READ_USER_CATEGORIES_ERROR';
export const SELECT_USER_CATEGORY_ERROR = 'SELECT_USER_CATEGORY_ERROR';
export const SELECT_USER_CATEGORY_SUCCESS = 'SELECT_USER_CATEGORY_SUCCESS';
export const SELECT_USER_CATEGORY_IN_PROGRESS = 'SELECT_USER_CATEGORY_IN_PROGRESS';
export const CREATE_USER_LINK_CATEGORY_ERROR = 'CREATE_USER_LINK_CATEGORY_ERROR';
export const CREATE_USER_LINK_CATEGORY_IN_PROGRESS = 'CREATE_USER_LINK_CATEGORY_IN_PROGRESS';
export const CREATE_USER_LINK_CATEGORY_SUCCESS = 'CREATE_USER_LINK_CATEGORY_SUCCESS';
export const UPDATE_USER_LINK_CATEGORY_IN_PROGRESS = 'UPDATE_USER_LINK_CATEGORY_IN_PROGRESS';
export const UPDATE_USER_LINK_CATEGORY_SUCCESS = 'UPDATE_USER_LINK_CATEGORY_SUCCESS';
export const UPDATE_USER_LINK_CATEGORY_ERROR = 'UPDATE_USER_LINK_CATEGORY_ERROR';
export const DELETE_USER_LINK_CATEGORY_IN_PROGRESS = 'DELETE_USER_LINK_CATEGORY_IN_PROGRESS';
export const DELETE_USER_LINK_CATEGORY_SUCCESS = 'DELETE_USER_LINK_CATEGORY_SUCCESS';
export const DELETE_USER_LINK_CATEGORY_ERROR = 'DELETE_USER_LINK_CATEGORY_ERROR';

export const TOGGLE_CATEGORY_CHECK = 'TOGGLE_CATEGORY_CHECK';
export const SAVE_CATEGORY_STATE = 'SAVE_CATEGORY_STATE';
export const SAVE_PRODUCT_STATE = 'SAVE_PRODUCT_STATE';
export const SAVE_REMOVED_CATEGORIES = 'SAVE_REMOVED_CATEGORIES';
export const LOAD_SAVED_CATEGORY_STATES = 'LOAD_SAVED_CATEGORY_STATES';
export const LOAD_SAVED_PRODUCT_STATES = 'LOAD_SAVED_PRODUCT_STATES';
export const LOAD_REMOVED_CATEGORIES = 'LOAD_REMOVED_CATEGORIES';

export const ADD_CATEGORY_TO_USER_LIST_REQUEST = 'ADD_CATEGORY_TO_USER_LIST_REQUEST';
export const ADD_CATEGORY_TO_USER_LIST_SUCCESS = 'ADD_CATEGORY_TO_USER_LIST_SUCCESS';
export const ADD_CATEGORY_TO_USER_LIST_FAILURE = 'ADD_CATEGORY_TO_USER_LIST_FAILURE';

export const REMOVE_CATEGORY_FROM_USER_LIST_REQUEST = 'REMOVE_CATEGORY_FROM_USER_LIST_REQUEST';
export const REMOVE_CATEGORY_FROM_USER_LIST_SUCCESS = 'REMOVE_CATEGORY_FROM_USER_LIST_SUCCESS';
export const REMOVE_CATEGORY_FROM_USER_LIST_FAILURE = 'REMOVE_CATEGORY_FROM_USER_LIST_FAILURE';

export const SET_EXPANDED_CATEGORIES = 'SET_EXPANDED_CATEGORIES';
export const SET_SHOW_SEARCH = 'SET_SHOW_SEARCH';
export const SET_SHOW_ADDED_CATEGORIES = 'SET_SHOW_ADDED_CATEGORIES';
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING';
export const SEARCH_CATEGORIES_SUCCESS = 'SEARCH_CATEGORIES_SUCCESS';
export const SEARCH_CATEGORIES_FAILURE = 'SEARCH_CATEGORIES_FAILURE';

export const UPDATE_USER_CATEGORIES = 'UPDATE_USER_CATEGORIES';
export const SAVE_USER_CATEGORIES_SUCCESS = 'SAVE_USER_CATEGORIES_SUCCESS';
export const SAVE_USER_CATEGORIES_ERROR = 'SAVE_USER_CATEGORIES_ERROR';

export const CLEAR_CATEGORY_STATES = 'CLEAR_CATEGORY_STATES';
export const READ_ALL_CATEGORIES_IN_ALL_TOPICS_SUCCESS = 'READ_ALL_CATEGORIES_IN_ALL_TOPICS_SUCCESS';

export const CATEGORY_LOADING = 'CATEGORY_LOADING';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const CATEGORY_SUCCESS = 'CATEGORY_SUCCESS';