import axios from 'axios';
import * as types from './actionTypes';
import { LOAD_SAVED_CATEGORY_STATES } from './actionTypes';
import { isEmpty } from '../../../app/helperFunctions';
import { readProductsInCategory } from '../products/actions';
import { readAllCategoriesInAllTopics } from '../topics/actions';


export function addCategoryError(error) {
  return {
    type: types.ADD_CATEGORY_ERROR,
    addCategoryError: error
  }
}

export function addCategoryInProgress(bool) {
  return {
    type: types.ADD_CATEGORY_IN_PROGRESS,
    addCategoryInProgress: bool
  }
}

function readAllCategoriesInProgress(bool) {
  return {
    type: types.READ_ALL_CATEGORIES_IN_PROGRESS,
    readAllCategoriesInProgress: bool
  }
}

function readAllCategoriesError(errMessage) {
  return {
    type: types.READ_ALL_CATEGORIES_ERROR,
    readAllCategoriesError: errMessage
  };
}

function readAllCategoriesSuccess(bool, categories) {
  return {
    type: types.READ_ALL_CATEGORIES_SUCCESS,
    readAllCategoriesSuccess: bool,
    allCategories: categories
  };
}

function readUserCategoriesInProgress(bool) {
  return {
    type: types.READ_USER_CATEGORIES_IN_PROGRESS,
    readUserCategoriesInProgress: bool
  }
}

function readUserCategoriesError(errMessage) {
  return {
    type: types.READ_USER_CATEGORIES_ERROR,
    readUserCategoriesError: errMessage
  };
}

function readUserCategoriesSuccess(bool, categories) {
  return {
    type: types.READ_USER_CATEGORIES_SUCCESS,
    readUserCategoriesSuccess: bool,
    userSelectedCategories: categories
  };
}

function updateUserSelectedCategoriesInProgress(bool) {
  return {
    type: types.SELECT_USER_CATEGORY_IN_PROGRESS,
    updateUserSelectedCategoriesInProgress: bool
  }
}

function updateUserSelectedCategoriesSuccess(bool) {
  return {
    type: types.SELECT_USER_CATEGORY_SUCCESS,
    updateUserSelectedCategoriesSuccess: bool
  }
}

function updateUserSelectedCategoriesError(message) {
  return {
    type: types.SELECT_USER_CATEGORY_ERROR,
    updateUserSelectedCategoriesError: message
  }
}

export function updateCategoryData(allCategories) {
  return {
    type: types.UPDATE_CATEGORY_DATA,
    allCategories: allCategories
  }
}

function createUserLinkCategoryError(error) {
  return {
    type: types.CREATE_USER_LINK_CATEGORY_ERROR,
    createUserLinkCategoryError: error
  }
}

function createUserLinkCategoryInProgress(bool) {
  return {
    type: types.CREATE_USER_LINK_CATEGORY_IN_PROGRESS,
    createUserLinkCategoryInProgress: bool
  }
}

function createUserLinkCategorySuccess(bool) {
  return {
    type: types.CREATE_USER_LINK_CATEGORY_SUCCESS,
    createUserLinkCategorySuccess: bool
  }
}

function updateUserLinkCategoryInProgress(bool) {
  return {
    type: types.UPDATE_USER_LINK_CATEGORY_IN_PROGRESS,
    updateUserLinkCategoryInProgress: bool
  }
}

function updateUserLinkCategorySuccess(bool) {
  return {
    type: types.UPDATE_USER_LINK_CATEGORY_SUCCESS,
    updateUserLinkCategorySuccess: bool
  }
}

function updateUserLinkCategoryError(error) {
  return {
    type: types.UPDATE_USER_LINK_CATEGORY_ERROR,
    updateUserLinkCategoryError: error
  }
}

function deleteUserLinkCategoryInProgress(bool) {
  return {
    type: types.DELETE_USER_LINK_CATEGORY_IN_PROGRESS,
    deleteUserLinkCategoryInProgress: bool
  }
}

function deleteUserLinkCategorySuccess(bool) {
  return {
    type: types.DELETE_USER_LINK_CATEGORY_SUCCESS,
    deleteUserLinkCategorySuccess: bool
  }
}

function deleteUserLinkCategoryError(error) {
  return {
    type: types.DELETE_USER_LINK_CATEGORY_ERROR,
    deleteUserLinkCategoryError: error
  }
}

export const addCategoryToUserList = (categoryId) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_CATEGORY_TO_USER_LIST_REQUEST });
    axios.post(`${process.env.REACT_APP_API_URL}/users/categories`, { category_uuid: categoryId })
      .then(response => {
        dispatch({
          type: types.ADD_CATEGORY_TO_USER_LIST_SUCCESS,
          payload: categoryId
        });
      })
      .catch(error => {
        dispatch({
          type: types.ADD_CATEGORY_TO_USER_LIST_FAILURE,
          payload: error.message
        });
      });
  };
};

export const removeCategoryFromUserList = (categoryId) => {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_CATEGORY_FROM_USER_LIST_REQUEST });
    axios.delete(`${process.env.REACT_APP_API_URL}/users/categories/${categoryId}`)
      .then(response => {
        dispatch({
          type: types.REMOVE_CATEGORY_FROM_USER_LIST_SUCCESS,
          payload: categoryId
        });
      })
      .catch(error => {
        dispatch({
          type: types.REMOVE_CATEGORY_FROM_USER_LIST_FAILURE,
          payload: error.message
        });
      });
  };
};

export function readAllCategories() {
  return(dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/categories`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(readAllCategoriesInProgress(true));
    axios.get(url, config)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(readAllCategoriesSuccess(true, response.data));
        } else {
          dispatch(readAllCategoriesSuccess(false, null));
        }
      })
      .catch(error => {
        let errMessage = 'Error fetching category data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(readAllCategoriesError(errMessage));
      })
      .finally(() => {
        dispatch(readAllCategoriesInProgress(false));
        dispatch(readAllCategoriesError(''));
      });
  }
}

export function readUserSelectedCategories(currentFriendUuid) {
  return(dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/categories`;
    let config = {
      headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')},
      params: { friendUuid: currentFriendUuid }
    };
    dispatch(readUserCategoriesInProgress(true));
    axios.get(url, config)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(readUserCategoriesSuccess(true, response.data));
        } else {
          dispatch(readUserCategoriesSuccess(true, []));
        }
      })
      .catch(error => {
        let errMessage = 'Error fetching category data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(readUserCategoriesError(errMessage));
      })
      .finally(() => {
        dispatch(readUserCategoriesInProgress(false));
        dispatch(readUserCategoriesError(''));
      });
  }
}

export function updateUserSelectedCategories(categoryUuids) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/categories`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(updateUserSelectedCategoriesInProgress(true));
    let data = {
      uuids: categoryUuids
    }
    axios.patch(url, data, config)
    .then((response) => {
      dispatch(updateUserSelectedCategoriesSuccess(true));
      dispatch(readUserSelectedCategories());
    })
    .catch(error => {
      let errMessage = 'Error selecting category. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(updateUserSelectedCategoriesError(errMessage));
    })
    .finally(() => {
      dispatch(updateUserSelectedCategoriesError(''));
      dispatch(updateUserSelectedCategoriesSuccess(false));
      dispatch(updateUserSelectedCategoriesInProgress(false));
    })
  }
}

export function createUserLinkCategory(categories_uuid, researched, favorited, extension) {
  return (dispatch) => {
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let url = `${process.env.REACT_APP_API_URL}/users/categories`;
    const data = {
      categories_uuid: categories_uuid,
      researched: researched,
      favorited: favorited
    };
    dispatch(createUserLinkCategoryInProgress(true));
    axios.post(url, data, config)
      .then((response) => {
        dispatch(createUserLinkCategorySuccess(true));
        dispatch(createUserLinkCategoryInProgress(false));
      })
      .then(() => {
        let url_favorited = extension.split("favorited").pop().split("=").pop();
        let url_friend_uuid = extension.split("friendUuid").pop().split("=").pop();
        if (url_friend_uuid !== "true" && url_friend_uuid !== "false") {
          dispatch(readAllCategoriesInAllTopics(true, url_friend_uuid));
        } else if (url_favorited !== "true" && url_favorited !== "false") {
          dispatch(readAllCategoriesInAllTopics());
        } else {
          dispatch(readAllCategoriesInAllTopics(url_favorited));
        }
      })
      .catch(error => {
        let errMessage = 'Error updating category';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(createUserLinkCategoryError(errMessage));
        dispatch(createUserLinkCategoryInProgress(false));
      })
      .finally(() => {
        dispatch(createUserLinkCategoryError(''));
        dispatch(createUserLinkCategorySuccess(false));
        dispatch(createUserLinkCategoryInProgress(false));
      });
  }
}

export function updateUserLinkCategory(category_uuid, researched, favorited, extension) {
  return (dispatch) => {
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let url = `${process.env.REACT_APP_API_URL}/users/categories/${category_uuid}`;
    const data = {
      researched: researched,
      favorited: favorited,
    };
    dispatch(updateUserLinkCategoryInProgress(true));
    axios.patch(url, data, config)
      .then((response) => {
        let url_favorited = extension.split("favorited").pop().split("=").pop();
        let url_friend_uuid = extension.split("friendUuid").pop().split("=").pop();
        if (url_friend_uuid !== "true" && url_friend_uuid !== "false") {
          dispatch(readAllCategoriesInAllTopics(true, url_friend_uuid));
        } else if (url_favorited !== "true" && url_favorited !== "false") {
          dispatch(readAllCategoriesInAllTopics());
        } else {
          dispatch(readAllCategoriesInAllTopics(url_favorited));
        }
        dispatch(updateUserLinkCategorySuccess(true));
        dispatch(updateUserLinkCategoryInProgress(false));
      })
      .catch(error => {
        let errMessage = 'Error updating category';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                    errMessage = error.response.data['Error'];
                  }
                } else {
                  errMessage = error.response.data;
                }
              }
            }
          }
          dispatch(updateUserLinkCategoryError(errMessage));
          dispatch(updateUserLinkCategoryInProgress(false));
        })
        .finally(() => {
          dispatch(updateUserLinkCategoryError(''));
          dispatch(updateUserLinkCategorySuccess(false));
          dispatch(updateUserLinkCategoryInProgress(false));
        });
    }
  }

  export function deleteUserLinkCategory(category_uuid, product_uuid, extension) {
    return (dispatch) => {
      let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
      let url = `${process.env.REACT_APP_API_URL}/users/categories/${category_uuid}`;
      dispatch(deleteUserLinkCategoryInProgress(true));
      axios.delete(url, config)
      .then((response) => {
        dispatch(deleteUserLinkCategorySuccess(true));
        dispatch(deleteUserLinkCategoryInProgress(false));
      })
      .then(() => {
        if (!isEmpty(product_uuid)) {
          dispatch(readProductsInCategory(category_uuid));
        } else {
          let url_favorited = extension.split("favorited").pop().split("=").pop();
          let url_friend_uuid = extension.split("friendUuid").pop().split("=").pop();
          if (url_friend_uuid !== "true" && url_friend_uuid !== "false") {
            dispatch(readAllCategoriesInAllTopics(true, url_friend_uuid));
          } else if (url_favorited !== "true" && url_favorited !== "false") {
            dispatch(readAllCategoriesInAllTopics());
          } else {
            dispatch(readAllCategoriesInAllTopics(url_favorited));
          }
        }
      })
      .catch(error => {
        let errMessage = 'Error updating category';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(deleteUserLinkCategoryError(errMessage));
        dispatch(deleteUserLinkCategoryInProgress(false));
      })
      .finally(() => {
        dispatch(deleteUserLinkCategoryError(''));
        dispatch(deleteUserLinkCategorySuccess(false));
        dispatch(deleteUserLinkCategoryInProgress(false));
      });
    }
  }

  export const toggleCategoryCheck = (categoryId) => ({
    type: types.TOGGLE_CATEGORY_CHECK,
    payload: categoryId,
  });

  export const saveCategoryState = (categoryId, isChecked) => {
    return (dispatch) => {
      const userUuid = sessionStorage.getItem('userUuid');
      const localStorageKey = `categoryStates_${userUuid}`;
      
      // Update Redux immediately
      dispatch({
        type: types.TOGGLE_CATEGORY_CHECK,
        payload: categoryId
      });
      
      // Update localStorage
      const savedStates = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
      savedStates[categoryId] = isChecked;
      localStorage.setItem(localStorageKey, JSON.stringify(savedStates));
      
      // Create or update UserLinkCategory
      const url = `${process.env.REACT_APP_API_URL}/users/categories/${categoryId}`;
      const config = { 
        headers: { 
          Authorization: 'Bearer ' + sessionStorage.getItem('jwt') 
        } 
      };
      
      const data = {
        categories_uuid: categoryId,
        researched: isChecked,
        favorited: false
      };
  
      return axios.patch(url, data, config)
        .catch(error => {
          console.error('Error saving category state:', error);
          // If PATCH fails (record doesn't exist), try POST
          if (error.response && error.response.status === 404) {
            return axios.post(`${process.env.REACT_APP_API_URL}/users/categories`, data, config);
          }
        });
    };
  };

  export const saveRemovedCategories = (removedCategories) => {
    return (dispatch) => {
      localStorage.setItem('removedCategories', JSON.stringify(Array.from(removedCategories)));
      dispatch({
        type: types.SAVE_REMOVED_CATEGORIES,
        payload: removedCategories
      });
    };
  };

  export const loadRemovedCategories = () => {
    return (dispatch) => {
      const savedRemovedCategories = JSON.parse(localStorage.getItem('removedCategories') || '[]');
      dispatch({
        type: types.LOAD_REMOVED_CATEGORIES,
        payload: new Set(savedRemovedCategories)
      });
    };
  };

  export const saveProductState = (categoryId, productId, isInPlan) => {
    return (dispatch) => {
      const savedStates = JSON.parse(localStorage.getItem('productStates') || '{}');
      if (!savedStates[categoryId]) {
        savedStates[categoryId] = {};
      }
      savedStates[categoryId][productId] = isInPlan;
      localStorage.setItem('productStates', JSON.stringify(savedStates));

      dispatch({
        type: types.SAVE_PRODUCT_STATE,
        payload: { categoryId, productId, isInPlan }
      });
    };
  };

  export const loadSavedCategoryStates = () => {
    return (dispatch) => {
      const userUuid = sessionStorage.getItem('userUuid');
      console.log('Loading saved category states for user:', userUuid);
   
      if (!userUuid) {
        console.log('No userUuid found in sessionStorage');
        return;
      }
   
      // First load from localStorage for immediate UI response
      const localStorageKey = `categoryStates_${userUuid}`;
      const cachedStates = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
      
      if (Object.keys(cachedStates).length > 0) {
        dispatch({
          type: types.LOAD_SAVED_CATEGORY_STATES,
          payload: cachedStates
        });
      }
   
      // Then fetch from backend to ensure accuracy
      const url = `${process.env.REACT_APP_API_URL}/users/categories`; 
      const config = { 
        headers: { 
          Authorization: 'Bearer ' + sessionStorage.getItem('jwt') 
        } 
      };
   
      return axios.get(url, config)
        .then(response => {
          console.log('API response:', response.data);
          const categoryStates = {};
          
          // Each record in response should have categories_uuid and researched fields
          response.data.forEach(category => {
            categoryStates[category.categories_uuid] = category.researched;
          });
   
          console.log('Processed category states:', categoryStates);
   
          // Update localStorage with fresh data
          localStorage.setItem(localStorageKey, JSON.stringify(categoryStates));
   
          // Update Redux with backend data 
          dispatch({
            type: types.LOAD_SAVED_CATEGORY_STATES,
            payload: categoryStates
          });
        })
        .catch(error => {
          console.error('Error loading category states:', error);
          // On error, keep using localStorage data as fallback
        });
    };
   };

  // Clear category states on logout
    export const clearCategoryStates = () => {
      return (dispatch) => {
        const userUuid = sessionStorage.getItem('userUuid');
        if (userUuid) {
          localStorage.removeItem(`categoryStates_${userUuid}`);
        }
        dispatch({
          type: types.LOAD_SAVED_CATEGORY_STATES,
          payload: {}
        });
      };
    };

  export const loadSavedProductStates = () => {
    return (dispatch) => {
      const savedStates = JSON.parse(localStorage.getItem('productStates') || '{}');
      dispatch({
        type: types.LOAD_SAVED_PRODUCT_STATES,
        payload: savedStates
      });
    };
  };

  export const setExpandedCategories = (expandedCategories) => ({
    type: types.SET_EXPANDED_CATEGORIES,
    payload: expandedCategories,
  });

  export const setShowSearch = (showSearch) => ({
    type: types.SET_SHOW_SEARCH,
    payload: showSearch,
  });

  export const setShowAddedCategories = (showAddedCategories) => ({
    type: types.SET_SHOW_ADDED_CATEGORIES,
    payload: showAddedCategories,
  });

  export const setSearchLoading = (isLoading) => ({
    type: types.SET_SEARCH_LOADING,
    payload: isLoading,
  });

  export const searchCategories = (searchTerm) => {
    return async (dispatch) => {
      dispatch(setSearchLoading(true));
      try {
        console.log('Searching categories:', searchTerm);
      } catch (error) {
        console.error('Error searching categories:', error);
      } finally {
        dispatch(setSearchLoading(false));
      }
    };
  };

  export function updateCategories(categoriesToAdd, categoriesToRemove) {
    return (dispatch, getState) => {
      const { userSelectedCategories } = getState().categories;

      const updatedCategories = [
        ...userSelectedCategories.filter(cat => !categoriesToRemove.includes(cat)),
        ...categoriesToAdd.filter(cat => !userSelectedCategories.includes(cat))
      ];

      dispatch({
        type: types.UPDATE_USER_CATEGORIES,
        categories: updatedCategories
      });

      axios.post(`${process.env.REACT_APP_API_URL}/user/categories`, { categories: updatedCategories })
        .then(() => {
          dispatch({
            type: types.SAVE_USER_CATEGORIES_SUCCESS
          });
        })
        .catch(error => {
          dispatch({
            type: types.SAVE_USER_CATEGORIES_ERROR,
            error: error.message
          });
        });
    };
  }

  export function updateUserCategories(categoriesToAdd, categoriesToRemove) {
    return (dispatch, getState) => {
      const { userSelectedCategories } = getState().categories;

      const updatedCategories = [
        ...userSelectedCategories.filter(cat => !categoriesToRemove.includes(cat)),
        ...categoriesToAdd.filter(cat => !userSelectedCategories.includes(cat))
      ];

      dispatch({
        type: types.UPDATE_USER_CATEGORIES,
        categories: updatedCategories
      });
    };
  }
