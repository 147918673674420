import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Layout, Button } from "antd";
import { login } from '../redux/authentication/actions';
import WaitlistModal from "../components/modals/WaitlistModal";
import RequestPasswordResetModal from "../components/modals/RequestPasswordResetModal";
import LoginModal from "../components/modals/LoginModal";
import Logo from "../../assets/images/logo";
import Hero from '../components/Hero';
import ProductsSection from '../components/productsSection';
import SolutionSection from '../components/SolutionSection';
import AppExperienceSection from '../components/AppExperienceSection';
import PrivacySection from '../components/PrivacySection';
import ThankYouSection from '../components/ThankYouSection';
import "../../assets/css/views/Login.css";
import "../../assets/css/components/button.css";
import "../../assets/css/components/header.css";

const { Header, Content } = Layout;

export default function Login() {
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [requestPasswordResetModal, setRequestPasswordResetModal] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  
  const toggleRegistrationModalVisibility = () => {
    setRegisterModalVisible(!registerModalVisible);
  };

  const toggleRequestPasswordResetModalVisibility = () => {
    setRequestPasswordResetModal(!requestPasswordResetModal);
  };

  const toggleLoginModalVisibility = () => {
    setLoginModalVisible(!loginModalVisible);
  };

  return (
    <Layout style={{
      minHeight: '100vh',
      width: '100%',
      position: 'relative',
      overflowX: 'hidden'
    }}>
      <Header className="headerContainer" style={{ padding: '16px 24px' }}>
        <a className="headerLogo" href='#/MyList'>
          <Logo />
        </a>
        <div className="headerButtonsContainer" style={{ gap: '12px' }}>
          <Button
            onClick={toggleLoginModalVisibility}
            style={{
              background: 'transparent',
              color: '#6B4EFF',
              border: '1px solid #6B4EFF',
              borderRadius: '20px',
              height: '40px',
              padding: '0 24px',
            }}
          >
            Sign in
          </Button>
        </div>
      </Header>
      
      <Layout style={{ backgroundColor: 'white' }}>
        <Content style={styles.content}>
          <Hero 
            toggleRegistrationModalVisibility={toggleRegistrationModalVisibility}
            toggleLoginModalVisibility={toggleLoginModalVisibility}
          />
          <ProductsSection
            toggleRegistrationModalVisibility={toggleRegistrationModalVisibility}
           />
          <SolutionSection
            toggleRegistrationModalVisibility={toggleRegistrationModalVisibility}
           />
          <AppExperienceSection
            toggleRegistrationModalVisibility={toggleRegistrationModalVisibility}
           />
          <PrivacySection
            toggleRegistrationModalVisibility={toggleRegistrationModalVisibility}
           />
          <ThankYouSection
            toggleRegistrationModalVisibility={toggleRegistrationModalVisibility}
           />
        </Content>
      </Layout>

      <LoginModal 
        open={loginModalVisible}
        onClose={() => setLoginModalVisible(false)}
        toggleRegistrationModalVisibility={toggleRegistrationModalVisibility}
        toggleRequestPasswordResetModalVisibility={toggleRequestPasswordResetModalVisibility}
      />

      <WaitlistModal
        open={registerModalVisible}
        toggleRegistrationModalVisibility={toggleRegistrationModalVisibility}
      />

      <RequestPasswordResetModal
        open={requestPasswordResetModal}
        toggleRequestPasswordResetModalVisibility={toggleRequestPasswordResetModalVisibility}
      />
    </Layout>
  );
}

const styles = {
  content: {
    marginTop: 50,
    width: "100%",
    zIndex: 0,
    objectFit: "stretch",
  },
};