import { useState, useRef, useEffect } from 'react';

export const useVideoSequence = (isVisible = false) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const firstVideo = useRef(null);
  const secondVideo = useRef(null);
  const thirdVideo = useRef(null);
  const fourthVideo = useRef(null);  // Add ref for fourth video
  const hasStartedPlaying = useRef(false);

  useEffect(() => {
    if (isVisible && !hasStartedPlaying.current && firstVideo.current) {
      hasStartedPlaying.current = true;
      firstVideo.current.play().catch(error => 
        console.error('Error playing first video:', error)
      );
    }
  }, [isVisible]);

  useEffect(() => {
    const loadVideos = async () => {
      const videos = [
        firstVideo.current, 
        secondVideo.current, 
        thirdVideo.current,
        fourthVideo.current  // Add fourth video to loading
      ];
      for (let video of videos) {
        if (video) {
          await video.load();
        }
      }
      console.log('All videos loaded');
    };
    loadVideos();
  }, []);

  const handleFirstVideoEnd = async () => {
    console.log('First video ended');
    if (secondVideo.current) {
      try {
        secondVideo.current.currentTime = 0;
        await secondVideo.current.play();
        setCurrentVideoIndex(1);
      } catch (error) {
        console.error('Error playing second video:', error);
      }
    }
  };

  const handleSecondVideoEnd = async () => {
    console.log('Second video ended');
    if (thirdVideo.current) {
      try {
        thirdVideo.current.currentTime = 0;
        await thirdVideo.current.play();
        setCurrentVideoIndex(2);
      } catch (error) {
        console.error('Error playing third video:', error);
      }
    }
  };

  const handleThirdVideoEnd = async () => {
    console.log('Third video ended');
    if (fourthVideo.current) {
      try {
        fourthVideo.current.currentTime = 0;
        await fourthVideo.current.play();
        setCurrentVideoIndex(3);
      } catch (error) {
        console.error('Error playing fourth video:', error);
      }
    }
  };

  const handleFourthVideoEnd = async () => {
    console.log('Fourth video ended');
    if (firstVideo.current) {
      try {
        firstVideo.current.currentTime = 0;
        await firstVideo.current.play();
        setCurrentVideoIndex(0);
      } catch (error) {
        console.error('Error restarting sequence:', error);
      }
    }
  };

  return {
    currentVideoIndex,
    firstVideo,
    secondVideo,
    thirdVideo,
    fourthVideo,
    handleFirstVideoEnd,
    handleSecondVideoEnd,
    handleThirdVideoEnd,
    handleFourthVideoEnd
  };
};