const initialState = {
  updateTopicInProgress: false,
  updateTopicError: "",
  updateTopicSuccess: false,
  addTopicError: "",
  addTopicInProgress: false,
  readAllTopicsError: "",
  readAllTopicsSuccess: false,
  readAllTopicsInProgress: false,
  readUserTopicsError: "",
  readUserTopicsSuccess: false,
  readUserTopicsInProgress: false,
  updateUserSelectedTopicsInProgress: false,
  updateUserSelectedTopicsError: "",
  updateUserSelectedTopicsSuccess: false,
  allTopics: [],
  allCategoriesInTopics: [],
  userSelectedTopics: [],
  categories: [],
  readAllCategoriesInTopicInProgress: false,
  readAllCategoriesInTopicError: "",
  readAllCategoriesInAllTopicsInProgress: false,
  readAllCategoriesInAllTopicsError: "",
  readAllCategoriesInAllTopicsSuccess: false,
  checkedCategories: {},
};

export default initialState;
