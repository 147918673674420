import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Card } from 'antd';
import { readAllCategoriesInAllTopics, readAllTopics } from '../../../redux/topics/actions';
import {
 readUserSelectedCategories,
 toggleCategoryCheck,
 loadSavedCategoryStates,
 saveCategoryState,
 saveProductState
} from '../../../redux/categories/actions';
import ChecklistItemNew from './ChecklistItemNew';
import CustomCheckmark from './CustomCheckmark';

import _ from 'lodash';

const isDev = process.env.NODE_ENV === 'development';
const log = (...args) => isDev && console.log('ChecklistsView:', ...args);

const getTopicsState = state => state.topics;
const getCategoriesState = state => state.categories;

const selectAllTopics = createSelector(
 [getTopicsState],
 topics => topics.allCategoriesInTopics ?? []
);

const selectCheckedCategories = createSelector(
  [getCategoriesState, (state, props) => props.isViewingFriend, (state, props) => props.currentUserIndex, (state, props) => props.allUsers],
  (categories, isViewingFriend, currentUserIndex, allUsers) => {
    if (isViewingFriend && allUsers[currentUserIndex]) {
      return allUsers[currentUserIndex].checkedCategories || {};
    }
    return categories.checkedCategories || {};
  }
);

const selectSelectedProducts = createSelector(
 [getCategoriesState],
 categories => categories.selectedProducts ?? {}
);

// In ChecklistsView.js - Update useChecklistInitialization
const useChecklistInitialization = (topicId, dispatch, currentUserIndex, allUsers) => {
  const initializeRef = useRef(false);
  const mountedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    mountedRef.current = true;
    
    const initialize = async () => {
      if (!initializeRef.current && mountedRef.current) {
        setIsLoading(true);
        try {
          const currentUser = allUsers[currentUserIndex];
          const friendUuid = currentUser.id !== 'you' ? currentUser.id : null;
          
          await Promise.all([
            dispatch(readAllTopics()),
            dispatch(readAllCategoriesInAllTopics()),
            dispatch(readUserSelectedCategories(friendUuid)),
            friendUuid ? null : dispatch(loadSavedCategoryStates())
          ].filter(Boolean));
          
          if (mountedRef.current) {
            initializeRef.current = true;
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error initializing checklist:', error);
          if (mountedRef.current) {
            setIsLoading(false);
          }
        }
      }
    };

    initialize();

    return () => {
      mountedRef.current = false;
    };
  }, [dispatch, currentUserIndex, allUsers]);

  useEffect(() => {
    initializeRef.current = false;
  }, [topicId]);

  return { isLoading, isInitialized: initializeRef.current };
};

const ChecklistsView = ({
  topicId,
  isEmbedded,
  onNavigate,
  onCategoryExpand,
  expandedCategoryId,
  onAfterProductSelect,
  selectedTopicIndex,
  expandedTopicIndex,
  isDeselecting,
  transitionState,
  isViewingFriend,
  currentUserIndex,
  allUsers
 }) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const cardRef = useRef(null);
  const cardBodyRef = useRef(null);
 
  const allTopics = useSelector(selectAllTopics, _.isEqual);
  const checkedCategories = useSelector(
    state => {
      const selectedCategories = selectCheckedCategories(state, { 
        isViewingFriend, 
        currentUserIndex, 
        allUsers,
        forceUpdate: currentUserIndex // Add this 
      });
      return selectedCategories;
    },
    _.isEqual
  );
  const selectedProducts = useSelector(selectSelectedProducts, _.isEqual);
  const { isLoading, isInitialized } = useChecklistInitialization(topicId, dispatch);
 
  const [reorderedCategories, setReorderedCategories] = useState([]);
  const [selectedProductNames, setSelectedProductNames] = useState({});
  const [previousTopicId, setPreviousTopicId] = useState(null);
  const [previousCategories, setPreviousCategories] = useState([]);

  const currentTopic = useMemo(() => {
    if (isDeselecting && previousTopicId) {
      return allTopics.find(t => t.id === previousTopicId);
    }
    if (transitionState?.phase === 'collapse') {
      return allTopics.find(t => t.id === transitionState.currentTopicId);
    }
    return allTopics.find(topic => topic.id === parseInt(topicId)) || null;
  }, [allTopics, topicId, isDeselecting, previousTopicId, transitionState]);


 useEffect(() => {
   if (selectedProducts && Object.keys(selectedProducts).length > 0) {
     const productNames = {};
     Object.entries(selectedProducts).forEach(([categoryId, productId]) => {
       if (productId === 'none') {
         productNames[categoryId] = 'Select a product';
       }
     });
     setSelectedProductNames(prev => ({...prev, ...productNames}));
   }
 }, [selectedProducts]);

  useEffect(() => {
    if (currentTopic?.id && currentTopic.id !== previousTopicId && !isDeselecting) {
      setPreviousTopicId(currentTopic.id);
      setPreviousCategories(reorderedCategories);
    }
  }, [currentTopic?.id, reorderedCategories, isDeselecting, previousTopicId]);

  useEffect(() => {
    if (currentTopic?.id && currentTopic?.categories) {
      sessionStorage.setItem(`topic-${currentTopic.id}-categories`, JSON.stringify(currentTopic.categories));
    }
  }, [currentTopic?.id, currentTopic?.categories]);
 
 useEffect(() => {
   if (isInitialized && currentTopic?.categories) {
     const savedOrder = sessionStorage.getItem(`topic-${currentTopic.id}-order`);
     if (savedOrder) {
       try {
         const parsed = JSON.parse(savedOrder);
         setReorderedCategories(parsed);
       } catch (e) {
         console.error('Error parsing saved order:', e);
         setReorderedCategories(currentTopic.categories);
       }
     } else {
       setReorderedCategories(currentTopic.categories);
     }
   }
 }, [currentTopic?.categories, isInitialized]);

 const memoizedReorderedCategories = useMemo(() => {
  if (!currentTopic?.categories) return [];
  
  if (isDeselecting) {
    return previousCategories;
  }
  
  return currentTopic.categories.slice().sort((a, b) => {
    const aChecked = checkedCategories[a.uuid] || false;
    const bChecked = checkedCategories[b.uuid] || false;
    if (aChecked === bChecked) {
      const aIndex = currentTopic.categories.findIndex(cat => cat.uuid === a.uuid);
      const bIndex = currentTopic.categories.findIndex(cat => cat.uuid === b.uuid);
      return aIndex - bIndex;
    }
    return bChecked ? 1 : -1;
  });
}, [currentTopic?.categories, checkedCategories, isDeselecting, previousCategories]);

useEffect(() => {
  setReorderedCategories(currentTopic?.categories || []);
}, [currentUserIndex, isViewingFriend, currentTopic]);

 useEffect(() => {
   if (memoizedReorderedCategories.length > 0) {
     setReorderedCategories(memoizedReorderedCategories);

     if (currentTopic?.id) {
       sessionStorage.setItem(
         `topic-${currentTopic.id}-order`,
         JSON.stringify(memoizedReorderedCategories)
       );
     }
   }
 }, [memoizedReorderedCategories, currentTopic?.id]);

 const handleCategoryToggle = useCallback((categoryId) => {
  if (isViewingFriend) return;
   const newCheckedState = !checkedCategories[categoryId];
   
   dispatch(saveCategoryState(categoryId, newCheckedState));
   
   setReorderedCategories(prev => {
     const newOrder = [...prev];
     const index = newOrder.findIndex(cat => cat.uuid === categoryId);
     if (index !== -1) {
       const [item] = newOrder.splice(index, 1);
       if (newCheckedState) {
         const originalIndex = currentTopic.categories.findIndex(cat => cat.uuid === categoryId);
         newOrder.splice(originalIndex, 0, item);
       } else {
         newOrder.push(item);
       }
     }
     return newOrder;
   });

   if (newCheckedState && !selectedProducts[categoryId]) {
     onCategoryExpand(categoryId);
   } else {
     onCategoryExpand(null);
   }
  }, [dispatch, checkedCategories, currentTopic, selectedProducts, onCategoryExpand, isViewingFriend]);

  const handleProductSelect = useCallback((categoryId, productId, productName) => {
    if (isViewingFriend) return;
  
   dispatch(saveProductState(categoryId, productId));
   dispatch(toggleCategoryCheck(categoryId, true));
   dispatch(saveCategoryState(categoryId, true));
   setSelectedProductNames(prev => ({...prev, [categoryId]: productName}));
   
   setReorderedCategories(prev => {
     const newOrder = [...prev];
     const index = newOrder.findIndex(cat => cat.uuid === categoryId);
     if (index !== -1) {
       const [item] = newOrder.splice(index, 1);
       newOrder.push(item);
     }
     return newOrder;
   });

   if (onAfterProductSelect) {
     onAfterProductSelect(categoryId);
   }
  }, [dispatch, onAfterProductSelect, isViewingFriend]);

 const handleNoThanks = useCallback((categoryId) => {
   dispatch(saveProductState(categoryId, 'none'));
   dispatch(toggleCategoryCheck(categoryId, true));
   dispatch(saveCategoryState(categoryId, true));

   setSelectedProductNames(prev => ({...prev, [categoryId]: 'Select a product'}));
   setReorderedCategories(prev => {
     const newOrder = [...prev];
     const index = newOrder.findIndex(cat => cat.uuid === categoryId);
     if (index !== -1) {
       const [item] = newOrder.splice(index, 1);
       newOrder.push(item);
     }
     return newOrder;
   });

   onCategoryExpand(null);
 }, [dispatch, onCategoryExpand]);

 const renderCategory = useCallback((category, index) => {
   const isChecked = checkedCategories[category.uuid] || false;
   const isExpanded = expandedCategoryId === category.uuid;
   const selectedProductId = selectedProducts[category.uuid];
   const selectedProductName = selectedProductNames[category.uuid] || '';

   return (
     <div
       key={category.uuid}
       id={`category-content-${category.uuid}`}
     >
       <div
         style={{
           display: 'flex',
           alignItems: 'center',
           padding: '0.5rem 25px',
           border: '1px solid #e5e7eb',
           cursor: 'pointer',
           backgroundColor: 'white',
         }}
         onClick={() => onCategoryExpand(category.uuid)}
       >
         <div style={{ opacity: isChecked ? 0.6 : 1, display: 'flex', alignItems: 'center', flexGrow: 1 }}>
           <CustomCheckmark
             key={`checkmark-${category.uuid}`}
             id={category.uuid}
             checked={isChecked}
             onChange={(e) => {
               e.stopPropagation();
               handleCategoryToggle(category.uuid);
             }}
           />
           <span style={{
             marginLeft: '0.5rem',
             flexGrow: 1,
             textAlign: 'left',
             color: isChecked ? '#9CA3AF' : 'inherit'
           }}>
             {category.name}
           </span>
         </div>
         {selectedProductName && (
           <div style={{
             display: 'flex',
             alignItems: 'center',
             fontSize: '0.875rem',
             color: selectedProductName === 'Select a product' ? 'blue' : '#6B7280',
             whiteSpace: 'nowrap',
             overflow: 'hidden',
             textOverflow: 'ellipsis',
             maxWidth: '200px'
           }}>
             <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{selectedProductName}</span>
           </div>
         )}
       </div>

       {isExpanded && (
         <div style={{
           padding: '0.5rem 25px',
           backgroundColor: 'white',
           border: '1px solid #e5e7eb',
           borderTop: 'none',
           borderBottomLeftRadius: '4px',
           borderBottomRightRadius: '4px',
         }}>
           <ChecklistItemNew
             categoryId={category.uuid}
             categoryName={category.name}
             isExpanded={true}
             onToggle={() => {}}
             checked={isChecked}
             onProductSelect={handleProductSelect}
             onNoThanks={handleNoThanks}
             selectedProduct={selectedProductId}
             isViewingFriend={isViewingFriend}
           />
         </div>
       )}
     </div>
   );
 }, [checkedCategories, expandedCategoryId, selectedProducts, selectedProductNames, onCategoryExpand, handleCategoryToggle, handleProductSelect, handleNoThanks]);

 const topicContent = useMemo(() => {
  if (isDeselecting) {
    return previousCategories.map(renderCategory);
  }
  if (!currentTopic?.categories?.length) return null;
  return reorderedCategories.map(renderCategory);
}, [currentTopic?.categories, reorderedCategories, renderCategory, isDeselecting, previousCategories]);

 if (isLoading) {
   return <div>Loading...</div>;
 }

 if (!currentTopic) {
   return <div style={{
     opacity: isDeselecting ? 0 : 1,
     transition: 'opacity 0.3s ease-in-out'
   }}>No topic selected</div>;
 }

 if (!currentTopic?.categories?.length) {
   return <div>No categories available</div>;
 }

 if (reorderedCategories.length === 0 && currentTopic?.categories?.length > 0) {
   return <div>Loading categories...</div>;
 }

 return (
   <div 
     className="bg-white text-gray-600 font-sans font-light tracking-wider mt-0 rounded-xl"
     ref={containerRef}
     style={{
       opacity: transitionState?.phase === 'mount' ? 0 : 1,
       transform: `scale(${transitionState?.phase === 'transition' ? '1' : '0.98'})`,
       transition: 'all 0.3s ease-in-out'
     }}
   >
     <main className="max-w-7xl mx-auto flex flex-col gap-6 rounded-xl">
       <Card
         ref={cardRef}
         styles={{
           body: { padding: '0px', overflow: 'visible' }
         }}
       >
         <div ref={cardBodyRef}>
           {topicContent}
         </div>
       </Card>
     </main>
   </div>
 );
};

const propsAreEqual = (prevProps, nextProps) => {
  return prevProps.topicId === nextProps.topicId && 
         prevProps.expandedCategoryId === nextProps.expandedCategoryId &&
         prevProps.isEmbedded === nextProps.isEmbedded &&
         prevProps.isDeselecting === nextProps.isDeselecting && 
         prevProps.transitionState?.phase === nextProps.transitionState?.phase &&
         prevProps.isViewingFriend === nextProps.isViewingFriend && // Add these
         prevProps.currentUserIndex === nextProps.currentUserIndex && 
         _.isEqual(prevProps.allUsers, nextProps.allUsers);
 };

export default React.memo(ChecklistsView, propsAreEqual);