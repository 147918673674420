import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography } from 'antd';
import * as friendsFunctions from '../redux/friends/actions';
import FriendCards from '../components/cards/FriendCards.js';
import InviteCard from '../components/checklist/InviteCard';
import "../../assets/css/components/button.css";
import "../../assets/css/views/FriendsTable.css";

const { Title, Paragraph } = Typography;

class Friends extends Component {
  componentDidMount() {
    this.props.readAllUsersFriends();
    this.props.readReceivedFriendRequests();
    this.props.readSentFriendRequests();
  }

  handleViewFriendList = (key) => {
    this.props.history.push({
      pathname: "/categories",
      search: "?friendUuid=" + key,
    });
  };

  handleInvite = (values) => {
    console.log('Invite values:', values);
  };

  render() {
    const { friends, requestsSent } = this.props;

    return (
      <div className="friends-container">
        <Row justify="center" align="middle" className="header-section">
          <Col span={24} style={{ textAlign: 'center' }}>
            <Title level={2} className="header-title">It takes a village.</Title>
            <Paragraph className="subheader-title">Get help, then pass it on.</Paragraph>
            <InviteCard type="friends" onInvite={this.handleInvite} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FriendCards
              friends={friends}
              requestsSent={requestsSent}
              handleViewFriendList={this.handleViewFriendList}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  friends: state.friends.users || [],
  requestsSent: state.friends.requestsSent || [],
});

const mapDispatchToProps = (dispatch) => ({
  readReceivedFriendRequests: () => dispatch(friendsFunctions.readReceivedFriendRequests()),
  readSentFriendRequests: () => dispatch(friendsFunctions.readSentFriendRequests()),
  readAllUsersFriends: () => dispatch(friendsFunctions.readAllUsersFriends()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Friends);