// utils/email_validation.js

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,6}$/;

export const isValidEmail = (email) => {
  return EMAIL_REGEX.test(email);
};

export const emailValidationRule = {
  required: true,
  pattern: EMAIL_REGEX,
  message: "Please enter a valid email address."
};