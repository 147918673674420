import initialState from './initialState';
import * as types from './actionTypes';

const categoriesReducer = (state = initialState, action) => {
  if (typeof action !== 'undefined') {
    switch (action.type) {
      case types.ADD_CATEGORY_ERROR:
        return {
          ...state,
          addCategoryError: action.addCategoryError
        }
      case types.ADD_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          addCategoryInProgress: action.addCategoryInProgress
        }
      case types.UPDATE_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          updateCategoryInProgress: action.updateCategoryInProgress
        }
      case types.UPDATE_CATEGORY_SUCCESS:
        return {
          ...state,
          updateCategorySuccess: action.updateCategorySuccess
        }
      case types.UPDATE_CATEGORY_ERROR:
        return {
          ...state,
          updateCategoryError: action.updateCategoryError
        }
      case types.UPDATE_CATEGORY_DATA:
        return {
          ...state,
          userData: action.userData
        }
      case types.READ_ALL_CATEGORIES_IN_PROGRESS:
        return {
          ...state,
          readAllCategoriesInProgress: action.readAllCategoriesInProgress
        }
      case types.READ_ALL_CATEGORIES_SUCCESS:
        return {
          ...state,
          readAllCategoriesSuccess: action.readAllCategoriesSuccess,
          allCategories: action.allCategories
        }
      case types.READ_ALL_CATEGORIES_ERROR:
        return {
          ...state,
          readAllCategoriesError: action.readAllCategoriesError
        }
      case types.READ_USER_CATEGORIES_IN_PROGRESS:
        return {
          ...state,
          readUserCategoriesInProgress: action.readUserCategoriesInProgress
        }
      case types.READ_USER_CATEGORIES_SUCCESS:
        if (JSON.stringify(state.userSelectedCategories) !== JSON.stringify(action.userSelectedCategories)) {
          return {
            ...state,
            readUserCategoriesSuccess: action.readUserCategoriesSuccess,
            userSelectedCategories: action.userSelectedCategories
          };
        }
      case types.READ_USER_CATEGORIES_ERROR:
        return {
          ...state,
          readUserCategoriesError: action.readUserCategoriesError
        }
      case types.SELECT_USER_CATEGORY_ERROR:
        return {
          ...state,
          updateUserSelectedCategoriesError: action.updateUserSelectedCategoriesError
        }
      case types.SELECT_USER_CATEGORY_SUCCESS:
        return {
          ...state,
          updateUserSelectedCategoriesSuccess: action.updateUserSelectedCategoriesSuccess
        }
      case types.SELECT_USER_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          updateUserSelectedCategoriesInProgress: action.updateUserSelectedCategoriesInProgress
        }
      case types.CREATE_USER_LINK_CATEGORY_ERROR:
        return {
          ...state,
          createUserLinkCategoryError: action.createUserLinkCategoryError
        }
      case types.CREATE_USER_LINK_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          createUserLinkCategoryInProgress: action.createUserLinkCategoryInProgress
        }
      case types.CREATE_USER_LINK_CATEGORY_SUCCESS:
        return {
          ...state,
          createUserLinkCategorySuccess: action.createUserLinkCategorySuccess
        }
      case types.UPDATE_USER_LINK_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          updateUserLinkCategoryInProgress: action.updateUserLinkCategoryInProgress
        }
      case types.UPDATE_USER_LINK_CATEGORY_SUCCESS:
        return {
          ...state,
          updateUserLinkCategorySuccess: action.updateUserLinkCategorySuccess
        }
      case types.UPDATE_USER_LINK_CATEGORY_ERROR:
        return {
          ...state,
          updateUserLinkCategoryError: action.updateUserLinkCategoryError
        }
      case types.DELETE_USER_LINK_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          deleteUserLinkCategoryInProgress: action.deleteUserLinkCategoryInProgress
        }
      case types.DELETE_USER_LINK_CATEGORY_SUCCESS:
        return {
          ...state,
          deleteUserLinkCategorySuccess: action.deleteUserLinkCategorySuccess
        }
      case types.DELETE_USER_LINK_CATEGORY_ERROR:
        return {
          ...state,
          deleteUserLinkCategoryError: action.deleteUserLinkCategoryError
        }
      case types.TOGGLE_CATEGORY_CHECK:
        return {
          ...state,
          checkedCategories: {
            ...state.checkedCategories,
            [action.payload]: !state.checkedCategories[action.payload]
          }
        };
      case types.SAVE_CATEGORY_STATE:
        return {
          ...state,
          checkedCategories: {
            ...state.checkedCategories,
            [action.payload.categoryId]: action.payload.isChecked
          }
        }
      case types.SAVE_PRODUCT_STATE:
        return {
          ...state,
          userSelectedProducts: {
            ...state.userSelectedProducts,
            [action.payload.categoryId]: {
              ...state.userSelectedProducts[action.payload.categoryId],
              [action.payload.productId]: action.payload.isInPlan
            }
          }
        }
      case types.LOAD_SAVED_CATEGORY_STATES:
        return {
          ...state,
          checkedCategories: {
            ...state.checkedCategories,
            ...action.payload
          }
        };
      case types.LOAD_SAVED_PRODUCT_STATES:
        return {
          ...state,
          userSelectedProducts: action.payload
        }
      case types.ADD_CATEGORY_TO_USER_LIST_SUCCESS:
        return {
          ...state,
          userSelectedCategories: [...state.userSelectedCategories, action.payload]
        }
      case types.REMOVE_CATEGORY_FROM_USER_LIST_SUCCESS:
        return {
          ...state,
          userSelectedCategories: state.userSelectedCategories.filter(id => id !== action.payload)
        }
      case types.SAVE_REMOVED_CATEGORIES:
        return {
          ...state,
          removedCategories: action.payload
        };
      case types.LOAD_REMOVED_CATEGORIES:
        return {
          ...state,
          removedCategories: action.payload
        };
      case types.SET_EXPANDED_CATEGORIES:
        return {
          ...state,
          expandedCategories: action.payload,
        };
      case types.SET_SHOW_SEARCH:
        return {
          ...state,
          showSearch: action.payload,
        };
      case types.SET_SHOW_ADDED_CATEGORIES:
        return {
          ...state,
          showAddedCategories: action.payload,
        };
      case types.SET_SEARCH_LOADING:
        return {
          ...state,
          searchLoading: action.payload,
        };
      case types.SEARCH_CATEGORIES_SUCCESS:
        return {
          ...state,
          searchResults: action.payload,
        };
      case types.SEARCH_CATEGORIES_FAILURE:
        return {
          ...state,
          searchError: action.payload,
        };
      case types.READ_ALL_CATEGORIES_IN_ALL_TOPICS_SUCCESS:
        return {
          ...state,
          checkedCategories: {
            ...state.checkedCategories,
            ...(action.allCategoriesInTopics || []).reduce((acc, category) => ({
              ...acc,
              [category.uuid]: state.checkedCategories[category.uuid] || false
            }), {})
          }
        };
      default:
        return state;
    }
  } else {
    return state
  }
}

export default categoriesReducer;
