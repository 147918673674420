import { useState, useCallback } from 'react';
import ChatGPTService from './actions';

const useChatGPT = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendMessage = useCallback(async (message, options = {}) => {
    setLoading(true);
    setError(null);

    try {
      const messages = ChatGPTService.formatMessages(
        message,
        options.systemPrompt
      );

      const response = await ChatGPTService.getChatCompletion(messages, {
        temperature: options.temperature,
        max_tokens: options.maxTokens
      });

      return response.response;
    } catch (err) {
      setError(err.message || 'Failed to send message');
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    sendMessage,
    loading,
    error
  };
};

export default useChatGPT;
