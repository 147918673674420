import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button } from 'antd';
import womanUsingApp from '../../assets/login/woman_using_app.png';
import checkmarkIcon from '../../assets/login/checkmark_icon.png';
import friendsIcon from '../../assets/login/friends_icon.png';
import aiIcon from '../../assets/login/ai_icon.png';

export default function AppExperienceSection({ toggleRegistrationModalVisibility }) {
  const [imageVisible, setImageVisible] = useState(false);
  const [textVisible, setTextVisible] = useState(false);
  const imageRef = useRef(null);
  const textRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const imageObserver = new IntersectionObserver(
      ([entry]) => {
        setImageVisible(entry.isIntersecting);
      },
      {
        threshold: [0.1],
        rootMargin: isMobile ? '-10% 0px' : '-50px 0px'
      }
    );

    const textObserver = new IntersectionObserver(
      ([entry]) => {
        setTextVisible(entry.isIntersecting);
      },
      {
        threshold: [0.3],
        rootMargin: isMobile ? '-50px 0px' : '-50px 0px'
      }
    );

    if (imageRef.current) {
      imageObserver.observe(imageRef.current);
    }

    if (textRef.current) {
      textObserver.observe(textRef.current);
    }

    return () => {
      imageObserver.disconnect();
      textObserver.disconnect();
    };
  }, [isMobile]);

  const features = [
    {
      icon: checkmarkIcon,
      title: "Checklists",
      description: "that help you stay on track."
    },
    {
      icon: friendsIcon,
      title: "List Sharing",
      description: "to replace your friends' spreadsheets."
    },
    {
      icon: aiIcon,
      title: "Personal Assistant",
      description: "that helps you learn and make decisions."
    }
  ];

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        minHeight: isMobile ? '1000px' : '700px',
        height: isMobile ? 'auto' : '100vh',
        width: '100%',
        margin: 0,
        position: 'relative',
        background: 'white',
        padding: '50px 0',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Col
        xs={{ span: 24, order: 2 }}
        md={{ span: 12, order: 1 }}
        ref={imageRef}
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-start',
          alignItems: 'center',
          height: isMobile ? '800px' : '100%',
          overflow: 'hidden',
          position: 'relative',
          marginTop: isMobile ? '32px' : '0'
        }}
      >
        <img
          src={womanUsingApp}
          alt="Woman using app"
          style={{
            maxHeight: '100%',
            width: 'auto',
            objectFit: 'contain',
            opacity: imageVisible ? 1 : 0,
            transform: imageVisible ? 'translateX(0)' : 'translateX(-100%)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
            padding: isMobile ? '0 24px' : '0',
            position: isMobile ? 'relative' : 'absolute',
            left: isMobile ? 'auto' : '0'
          }}
        />
      </Col>
      
      <Col
        xs={{ span: 24, order: 1 }}
        md={{ span: 12, order: 2 }}
        ref={textRef}
        style={{
          paddingLeft: isMobile ? '24px' : '48px',
          paddingRight: isMobile ? '24px' : '64px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: isMobile ? 'center' : 'flex-start',
          marginBottom: isMobile ? '32px' : '0',
          ...(isMobile && {            
            margin: '0 24px 32px 24px',
            padding: '32px 24px',
            width: 'calc(100% - 48px)'
          })
        }}
      >
        <div style={{
          maxWidth: isMobile ? '100%' : '480px',
          width: '100%'
        }}>
          <h2
            style={{
              fontSize: '32px',
              fontWeight: '600',
              color: '#1F1F1F',
              marginBottom: '32px',
              textAlign: isMobile ? 'center' : 'left',
              opacity: textVisible ? 1 : 0,
              transform: textVisible ? 'translateX(0)' : 'translateX(100%)',
              transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
            }}
          >
            An experience for the modern parent.
          </h2>
          <div style={{ 
            marginTop: '24px',
            opacity: textVisible ? 1 : 0,
            transition: 'opacity 0.8s ease-out'
          }}>
            {features.map((feature, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                  opacity: textVisible ? 1 : 0,
                  transform: textVisible ? 'translateX(0)' : 'translateX(100%)',
                  transition: `opacity 0.8s ease-out, transform 0.8s ease-out`,
                }}
              >
                <img
                  src={feature.icon}
                  alt={`${feature.title} icon`}
                  style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '16px',
                  }}
                />
                <span
                  style={{
                    fontSize: '20px',
                    color: '#666666',
                    margin: '0',
                  }}
                >
                  <strong>{feature.title}</strong> {feature.description}
                </span>
              </div>
            ))}
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-start'
            }}>
              <Button
                onClick={toggleRegistrationModalVisibility}
                style={{
                  background: '#6B4EFF',
                  color: 'white',
                  border: 'none',
                  borderRadius: '20px',
                  height: '40px',
                  padding: '0 24px',
                  marginTop: '32px',
                  opacity: textVisible ? 1 : 0,
                  transform: textVisible ? 'translateX(0)' : 'translateX(100%)',
                  transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
                }}
              >
                Sign up
              </Button>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}