import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button } from 'antd';
import appDemo from '../../assets/login/app_demo.png';

export default function SolutionSection({ toggleRegistrationModalVisibility }) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: [0.3],
        rootMargin: '-50px 0px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Row
      ref={sectionRef}
      justify="center"
      align="middle"
      style={{
        minHeight: '700px',
        height: '100vh',
        width: '100%',
        margin: 0,
        position: 'relative',
        background: 'white',
        padding: '50px 0',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Col
        xs={24}
        md={12}
        style={{
          paddingLeft: isMobile ? '24px' : '64px',
          paddingRight: isMobile ? '24px' : '0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: isMobile ? 'center' : 'flex-start',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateX(0)' : 'translateX(-20px)',
          transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        }}
      >
        <div style={{
          maxWidth: isMobile ? '100%' : '480px',
          width: '100%'
        }}>
          <h2
            style={{
              fontSize: '32px',
              fontWeight: '600',
              color: '#1F1F1F',
              marginBottom: '16px',
              textAlign: isMobile ? 'center' : 'left',
            }}
          >
            One app, all the things
          </h2>
          <p
            style={{
              fontSize: '20px',
              color: '#666666',
              lineHeight: '1.4',
              margin: '0',
              textAlign: isMobile ? 'center' : 'left',
            }}
          >
            Explore thousands of products without spreadsheets, wishlists, and mom groups. Just a single place to stay organized and make decisions.
          </p>
          
          <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-start'
            }}>
              <Button
              onClick={toggleRegistrationModalVisibility}
              style={{
                background: '#6B4EFF',
                color: 'white',
                border: 'none',
                borderRadius: '20px',
                height: '40px',
                padding: '0 24px',
                marginTop: '32px',
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? 'translateX(0)' : 'translateX(-20px)',
                transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
              }}
            >
              Sign up
            </Button>
          </div>
        </div>
      </Col>
      
      <Col
        xs={24}
        md={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: isMobile ? '400px' : '600px',
          marginTop: isMobile ? '32px' : '0'
        }}
      >
        <img
          src={appDemo}
          alt="App Demo"
          style={{
            maxHeight: '100%',
            width: 'auto',
            padding: '50px 0',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'scale(1) translateY(0)' : 'scale(0.8) translateY(20px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
          }}
        />
      </Col>
    </Row>
  );
}