// contextData.ts

export const LIFESTYLE_FACTORS = {
  living_situation: {
    key: 'living_situation',
    question: 'Hi! I am here to help you learn about the different product options available to you.',
    // question: "Let's start with understanding your living situation. Do you live in an urban apartment, suburban house, or rural area?",
    options: ['urban_apartment', 'suburban_house', 'rural'],
    impacts: {
      'urban_apartment': [
        {
          category: 'Strollers',
          explanation: 'Living in an apartment often means navigating elevators, stairs, and limited storage. This suggests a lightweight, compact stroller would be most practical.',
          preferences_affected: ['weight', 'fold_type', 'storage']
        },
        {
          category: 'Car Seats',
          explanation: 'Urban living often involves ride-shares or public transit, suggesting a lightweight seat that can be installed easily without a base.',
          preferences_affected: ['portability', 'installation_ease']
        }
      ],
      'suburban_house': [
        {
          category: 'Strollers',
          explanation: 'With more storage space and likely car usage, you might prefer a full-featured stroller with ample storage.',
          preferences_affected: ['type', 'storage_capacity']
        },
        {
          category: 'Car Seats',
          explanation: 'With a consistent parking spot, you can consider options with a fixed base for easier daily use.',
          preferences_affected: ['installation_type', 'base_options']
        }
      ],
      'rural': [
        {
          category: 'Strollers',
          explanation: 'Rural living often means uneven terrain and longer trips. Consider all-terrain or jogging strollers.',
          preferences_affected: ['type', 'terrain_handling']
        },
        {
          category: 'Travel Gear',
          explanation: 'You might need more self-contained solutions since stores aren\'t always nearby.',
          preferences_affected: ['capacity', 'versatility']
        }
      ]
    },
    follow_up_questions: {
      'urban_apartment': 'Do you have elevator access or need to manage stairs?',
      'suburban_house': 'Do you have a garage or need to store baby gear indoors?',
      'rural': 'How far are you typically traveling for errands or appointments?'
    }
  },

  transportation: {
    key: 'transportation',
    question: "How do you plan to get around most days?",
    options: ['own_car', 'public_transit', 'mixed', 'mostly_walking'],
    impacts: {
      'public_transit': [
        {
          category: 'Strollers',
          explanation: 'Using public transit means you\'ll want a stroller that\'s lightweight and quick to fold.',
          preferences_affected: ['weight', 'fold_type']
        },
        {
          category: 'Carriers',
          explanation: 'A baby carrier would be very useful for public transit when a stroller might be impractical.',
          preferences_affected: ['carrier_type', 'comfort_features']
        },
        {
          category: 'Car Seats',
          explanation: 'Look for car seats that are lightweight and can be installed without a base for ride-shares.',
          preferences_affected: ['weight', 'installation_type']
        }
      ],
      'own_car': [
        {
          category: 'Car Seats',
          explanation: 'With your own car, you might prefer a car seat that stays installed, possibly with multiple bases.',
          preferences_affected: ['installation_type', 'base_options']
        },
        {
          category: 'Strollers',
          explanation: 'You can consider larger strollers since you\'ll be transporting them by car.',
          preferences_affected: ['size', 'features']
        }
      ],
      'mixed': [
        {
          category: 'Car Seats',
          explanation: 'You\'ll need versatile car seats that work well both installed with a base and without.',
          preferences_affected: ['installation_type', 'versatility']
        },
        {
          category: 'Strollers',
          explanation: 'Consider a mid-weight stroller that balances features with portability.',
          preferences_affected: ['weight', 'versatility']
        }
      ],
      'mostly_walking': [
        {
          category: 'Strollers',
          explanation: 'Prioritize good suspension and maneuverability for frequent walking.',
          preferences_affected: ['suspension', 'maneuverability']
        },
        {
          category: 'Carriers',
          explanation: 'A comfortable carrier would be great for times when a stroller isn\'t practical.',
          preferences_affected: ['comfort', 'support']
        }
      ]
    }
  },

  travel_frequency: {
    key: 'travel_frequency',
    question: "Do you expect to travel frequently with your baby? If so, what type of travel?",
    options: ['frequent_fly', 'frequent_drive', 'occasional', 'rare'],
    impacts: {
      'frequent_fly': [
        {
          category: 'Car Seats',
          explanation: 'Frequent flying suggests prioritizing an FAA-approved, lightweight car seat.',
          preferences_affected: ['certifications', 'weight']
        },
        {
          category: 'Strollers',
          explanation: 'Look for a stroller that fits in overhead bins or gate-checks easily.',
          preferences_affected: ['fold_type', 'travel_features']
        },
        {
          category: 'Travel Gear',
          explanation: 'Consider a complete travel system with carrying bags for your gear.',
          preferences_affected: ['portability', 'travel_features']
        }
      ],
      'frequent_drive': [
        {
          category: 'Car Seats',
          explanation: 'Consider comfort features and easy transfer between vehicles.',
          preferences_affected: ['comfort', 'installation_ease']
        },
        {
          category: 'Travel Gear',
          explanation: 'Look for gear that maximizes car space efficiency.',
          preferences_affected: ['organization', 'storage']
        }
      ]
    }
  },

  activity_level: {
    key: 'activity_level',
    question: "What kinds of activities do you envision doing with your baby?",
    options: ['outdoor_active', 'urban_social', 'mixed_casual', 'mostly_indoor'],
    impacts: {
      'outdoor_active': [
        {
          category: 'Carriers',
          explanation: 'For outdoor activities, consider a structured carrier with good support and weather protection.',
          preferences_affected: ['carrier_type', 'features']
        },
        {
          category: 'Strollers',
          explanation: 'You might want an all-terrain or jogging stroller for outdoor activities.',
          preferences_affected: ['type', 'terrain_handling']
        }
      ],
      'urban_social': [
        {
          category: 'Strollers',
          explanation: 'Consider a stroller that\'s easy to maneuver in cafes and shops.',
          preferences_affected: ['size', 'maneuverability']
        },
        {
          category: 'Travel Gear',
          explanation: 'Look for diaper bags that look stylish but are functional.',
          preferences_affected: ['style', 'organization']
        }
      ]
    }
  },

  storage_constraints: {
    key: 'storage_constraints',
    question: "How would you describe your storage situation at home?",
    options: ['very_limited', 'moderate', 'ample'],
    impacts: {
      'very_limited': [
        {
          category: 'Strollers',
          explanation: 'With limited storage, you\'ll want a stroller that folds compactly and possibly serves multiple purposes.',
          preferences_affected: ['fold_type', 'versatility']
        },
        {
          category: 'Travel Gear',
          explanation: 'Look for items that are collapsible or serve multiple purposes to maximize space.',
          preferences_affected: ['portability', 'multi_use']
        },
        {
          category: 'Car Seats',
          explanation: 'A convertible car seat might be better than having multiple seats as your child grows.',
          preferences_affected: ['type', 'longevity']
        }
      ],
      'ample': [
        {
          category: 'Strollers',
          explanation: 'You have flexibility to choose specialized strollers for different purposes.',
          preferences_affected: ['specialization', 'features']
        },
        {
          category: 'Travel Gear',
          explanation: 'You can consider having separate gear for different situations.',
          preferences_affected: ['specialization', 'organization']
        }
      ]
    }
  },

  support_system: {
    key: 'support_system',
    question: "Will you usually be managing baby gear alone or have help available?",
    options: ['mostly_alone', 'sometimes_help', 'usually_help'],
    impacts: {
      'mostly_alone': [
        {
          category: 'Strollers',
          explanation: 'You\'ll want gear that\'s easy to manage solo, like one-handed fold strollers.',
          preferences_affected: ['fold_type', 'maneuverability']
        },
        {
          category: 'Car Seats',
          explanation: 'Lightweight car seats with easy installation features are important.',
          preferences_affected: ['weight', 'installation_ease']
        },
        {
          category: 'Carriers',
          explanation: 'Consider carriers you can put on and adjust by yourself.',
          preferences_affected: ['ease_of_use', 'adjustability']
        }
      ],
      'usually_help': [
        {
          category: 'Strollers',
          explanation: 'You have more flexibility to consider features over ease of solo use.',
          preferences_affected: ['features', 'capacity']
        }
      ]
    }
  },

  budget_approach: {
    key: 'budget_approach',
    question: "What's your approach to buying baby gear?",
    options: ['invest_long_term', 'balanced', 'budget_conscious'],
    impacts: {
      'invest_long_term': [
        {
          category: 'Car Seats',
          explanation: 'Consider convertible seats that will last through multiple stages.',
          preferences_affected: ['type', 'longevity']
        },
        {
          category: 'Strollers',
          explanation: 'Look for versatile, high-quality strollers that can handle multiple children.',
          preferences_affected: ['quality', 'versatility']
        }
      ],
      'budget_conscious': [
        {
          category: 'Travel Gear',
          explanation: 'Focus on essential, multi-purpose items rather than specialized gear.',
          preferences_affected: ['versatility', 'essentials']
        },
        {
          category: 'Strollers',
          explanation: 'Consider lighter-weight options that meet basic needs effectively.',
          preferences_affected: ['essentials', 'value']
        }
      ]
    }
  },

  climate_weather: {
    key: 'climate_weather',
    question: "What type of weather will you regularly encounter?",
    options: ['all_seasons', 'mostly_warm', 'mostly_cold', 'rainy'],
    impacts: {
      'all_seasons': [
        {
          category: 'Strollers',
          explanation: 'You\'ll want good weather protection options for various conditions.',
          preferences_affected: ['weather_protection', 'versatility']
        },
        {
          category: 'Travel Gear',
          explanation: 'Consider gear with weather protection features built-in.',
          preferences_affected: ['weather_protection', 'adaptability']
        }
      ],
      'rainy': [
        {
          category: 'Strollers',
          explanation: 'Prioritize good coverage and weather shields.',
          preferences_affected: ['weather_protection', 'coverage']
        },
        {
          category: 'Travel Gear',
          explanation: 'Look for water-resistant materials and rain protection.',
          preferences_affected: ['materials', 'weather_protection']
        }
      ]
    }
  },

  daily_routine: {
    key: 'daily_routine',
    question: "What best describes your planned daily routine with baby?",
    options: ['frequent_outings', 'mostly_home', 'mixed_routine'],
    impacts: {
      'frequent_outings': [
        {
          category: 'Strollers',
          explanation: 'Durability and ease of use become more important with frequent use.',
          preferences_affected: ['durability', 'ease_of_use']
        },
        {
          category: 'Travel Gear',
          explanation: 'Consider robust, well-organized gear for daily outings.',
          preferences_affected: ['durability', 'organization']
        }
      ],
      'mostly_home': [
        {
          category: 'Carriers',
          explanation: 'A comfortable carrier for around-house use might be more important than a stroller.',
          preferences_affected: ['comfort', 'indoor_use']
        }
      ]
    }
  },

  space_management: {
    key: 'space_management',
    question: "Will you need to keep baby gear in multiple locations (different cars, daycare, grandparents)?",
    options: ['single_location', 'two_locations', 'multiple_locations'],
    impacts: {
      'multiple_locations': [
        {
          category: 'Car Seats',
          explanation: 'Consider multiple bases or seats for different locations.',
          preferences_affected: ['portability', 'installation_type']
        },
        {
          category: 'Travel Gear',
          explanation: 'You might want duplicate basic supplies for each location.',
          preferences_affected: ['essentials', 'organization']
        }
      ],
      'single_location': [
        {
          category: 'Car Seats',
          explanation: 'You can focus on one comprehensive car seat solution.',
          preferences_affected: ['features', 'quality']
        }
      ]
    }
  }
};

function getImpacts(factor, response) {
  const factorData = LIFESTYLE_FACTORS[factor];
  if (!factorData || !factorData.impacts[response]) {
    return [];
  }
  return factorData.impacts[response];
}

function getInitialQuestions() {
  return Object.values(LIFESTYLE_FACTORS)
    .sort((a, b) => (a.key === 'living_situation' ? -1 : 1))
    .map(factor => factor.question);
}

export { getImpacts, getInitialQuestions };