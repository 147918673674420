export const READ_PRODUCTS_IN_CATEGORY_SUCCESS = 'READ_PRODUCTS_IN_CATEGORY_SUCCESS';
export const READ_PRODUCTS_IN_CATEGORY_IN_PROGRESS = 'READ_PRODUCTS_IN_CATEGORY_IN_PROGRESS';
export const READ_PRODUCTS_IN_CATEGORY_ERROR = 'READ_PRODUCTS_IN_CATEGORY_ERROR';
export const CREATE_USER_LINK_PRODUCT_ERROR = 'CREATE_USER_LINK_PRODUCT_ERROR';
export const UPDATE_USER_LINK_PRODUCT_ERROR = 'UPDATE_USER_LINK_PRODUCT_ERROR';
export const DELETE_USER_LINK_PRODUCT_ERROR = 'DELETE_USER_LINK_PRODUCT_ERROR';
export const READ_CATEGORIES_WITH_PRODUCTS_IN_PROGRESS = 'READ_CATEGORIES_WITH_PRODUCTS_IN_PROGRESS';
export const READ_CATEGORIES_WITH_PRODUCTS_ERROR = 'READ_CATEGORIES_WITH_PRODUCTS_ERROR';
export const READ_CATEGORIES_WITH_PRODUCTS_SUCCESS = 'READ_CATEGORIES_WITH_PRODUCTS_SUCCESS';

export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const SAVE_PRODUCT_STATE = 'SAVE_PRODUCT_STATE';
export const LOAD_SAVED_PRODUCT_STATES = 'LOAD_SAVED_PRODUCT_STATES';
export const CLEAR_PRODUCT_STATES = 'CLEAR_PRODUCT_STATES';