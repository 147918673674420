import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Form, Input, Button, message, notification } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { sendWaitlistEmail } from '../../redux/authentication/actions';
import { isValidEmail } from '../Utils/email_validation';

const WaitlistForm = ({ open, onCancel, onCreate, form, sendWaitlistInProcess }) => {
  const [formInstance] = Form.useForm();
  const [email, setEmail] = React.useState('');
  
  React.useEffect(() => {
    if (!open) {
      setEmail('');
      formInstance.resetFields();
    }
  }, [open, formInstance]);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      width={250}
      centered
      maskClosable={true}
      closable={false}
    >
      <p style={{
        fontSize: 24,
        marginTop: 10,
        marginBottom: 24,
        textAlign: 'center',
        color: '#1F1F1F',
        fontWeight: '600'
      }}>
        Join the waitlist
      </p>
      <p style={{
        fontSize: '16px',
        color: '#666666',
        margin: '0 0 24px 0',
        textAlign: 'center',
        lineHeight: '1.4',
      }}>
        Due to high demand, our app is currently invite only.
      </p>
      <Form 
        form={formInstance}
        ref={form}
      >
        <Form.Item
          name="email"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Input
            prefix={<MailOutlined style={{ color: '#666666' }} />}
            placeholder='Enter email address'
            size='large'
            onChange={handleEmailChange}
            value={email}
            style={{ 
              borderRadius: '6px',
              height: '44px',
              paddingTop: '0',
              paddingBottom: '0',
              display: 'flex',
              alignItems: 'center',
              maxWidth: '325px',
            }}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0, textAlign: 'center' }}>
          <Button
            onClick={onCreate}
            disabled={!isValidEmail(email) || sendWaitlistInProcess}
            style={{
              height: '44px',
              background: !isValidEmail(email) ? '#E8E8E8' : '#6B4EFF',
              color: !isValidEmail(email) ? '#999999' : 'white',
              border: 'none',
              borderRadius: '6px',
              fontSize: '16px',
              fontWeight: '500',
              maxWidth: '250px',
              transition: 'all 0.3s ease',
              transform: isValidEmail(email) ? 'scale(1.02)' : 'scale(1)',
            }}
            onMouseEnter={(e) => {
              if (isValidEmail(email)) {
                e.currentTarget.style.transform = 'scale(1.05)';
              }
            }}
            onMouseLeave={(e) => {
              if (isValidEmail(email)) {
                e.currentTarget.style.transform = 'scale(1.02)';
              }
            }}
          >
            {sendWaitlistInProcess ? 'Signing up...' : 'Join waitlist'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

WaitlistForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  form: PropTypes.func.isRequired,
  sendWaitlistInProcess: PropTypes.bool.isRequired
};

class WaitlistModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    toggleRegistrationModalVisibility: PropTypes.func.isRequired, // Updated prop name
    sendWaitlistInProcess: PropTypes.bool.isRequired,
    sendWaitlistError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    sendWaitlistSuccess: PropTypes.bool,
    sendWaitlistEmail: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      type: props.type
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.sendWaitlistSuccess && prevProps.sendWaitlistSuccess !== this.props.sendWaitlistSuccess) {
      notification.success({
        message: 'Successfully Joined!',
        description: 'Thanks for joining our waitlist. We\'ll send you an email when it\'s your turn.',
        placement: 'topRight',
        duration: 4.5
      });
      this.form.resetFields();
      this.props.toggleRegistrationModalVisibility(); // Updated prop name
    }
    if (this.props.sendWaitlistError && prevProps.sendWaitlistError !== this.props.sendWaitlistError) {
      let errorMessage;
      
      switch(this.props.sendWaitlistError) {
        case 'EMAIL_EXISTS':
          errorMessage = 'This email is already on our waitlist.';
          break;
        case 'INVALID_EMAIL':
          errorMessage = 'Please enter a valid email address.';
          break;
        case true:
          errorMessage = 'An error occurred while joining the waitlist.';
          break;
        default:
          errorMessage = 'Sorry, something went wrong. Please try again.';
      }
      
      message.error(errorMessage, 5);
    }
  }

  handleCancel = () => {
    this.form?.resetFields();
    this.props.toggleRegistrationModalVisibility(); // Updated prop name
  };

  handleCreate = () => {
    if (this.form) {
      this.form.validateFields().then(values => {
        this.props.sendWaitlistEmail(values.email.toLowerCase());
      });
    }
  };

  saveFormRef = (form) => {
    this.form = form;
  };

  render() {
    return (
      <WaitlistForm
        form={this.saveFormRef}
        open={this.props.open}
        onCancel={this.handleCancel}
        onCreate={this.handleCreate}
        sendWaitlistInProcess={this.props.sendWaitlistInProcess}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  sendWaitlistInProcess: state.auth.sendWaitlistInProcess,
  sendWaitlistError: state.auth.sendWaitlistError,
  sendWaitlistSuccess: state.auth.sendWaitlistSuccess
});

const mapDispatchToProps = (dispatch) => ({
  sendWaitlistEmail: (email) => dispatch(sendWaitlistEmail(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitlistModal);