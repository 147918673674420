import React, { useState } from "react";
import { connect } from "react-redux";
import { createSelector } from 'reselect';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
  Link
} from "react-router-dom";
import { Layout } from "antd";
import { TeamOutlined } from '@ant-design/icons';
import SideMenu from "../components/navigation/SideMenu";
import DueDate from "../views/Onboarding";
import NewCategories from "../components/checklist/NewCategories";
import Friends from "../views/Friends";
import Notepad from "../views/NotePad";
import Dashboard from "../views/Dashboard";
import Home from "../components/Home/Views/Home";
import { setUserType } from "../redux/users/actions";
import "../../assets/css/components/button.css";
import "../../assets/css/components/header.css";
import Logo from "../../assets/images/logo";

const { Header, Sider, Content } = Layout;

export const userTypes = {
  personal: { name: "Personal", color: "#FFB3BA" },
  expert: { name: "Expert", color: "#FFFFBA" }
};

const AppRouter = (props) => {
  const [collapsed] = useState(true);

  return (
    <Router>
      <Layout>
        <Header className="headerContainer">
          <div className="headerContent" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: 10 }}>
            <Link className="headerLogo" to="/home" style={{ marginLeft: '10px' }}>
              <Logo/>
            </Link>
          </div>
        </Header>
        <Layout>
          <Content className="mainContent">
            <Switch>
              <Route exact path="/">
                {props.user ? <Redirect to="/home" /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/login">
                {props.user ? <Redirect to="/home" /> : <div>Login component here</div>}
              </Route>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/Notepad" component={Notepad} />
              <Route exact path="/onboarding" component={DueDate} />
              <Route exact path="/categories" component={NewCategories} />
              <Route exact path="/friends" component={Friends} />
              <Route exact path="/home" component={Home} />
              <Route path="*" component={() => <div>Page not found</div>} />
            </Switch>
          </Content>
          <Sider
            collapsed={collapsed}
            collapsedWidth={0}
            className="sider"
          >
            <SideMenu user={props.user} />
          </Sider>
        </Layout>
      </Layout>
      <style jsx>{`
        .team-icon {
          transition: color 0.3s ease;
        }
        .team-icon:hover {
          color: #7E3FFF !important;
        }
      `}</style>
    </Router>
  );
};

const getUserState = state => state.auth.user;
const getIsSuperuserState = state => state.users.isSuperuser;
const getCurrentUserTypeState = state => state.users.currentUserType;

const getAppRouterProps = createSelector(
  [getUserState, getIsSuperuserState, getCurrentUserTypeState],
  (user, isSuperuser, currentUserType) => ({
    user,
    isSuperuser,
    currentUserType
  })
);

const mapStateToProps = (state) => {
  const props = getAppRouterProps(state);
  return props;
};

const mapDispatchToProps = {
  setUserType,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppRouter));