const state = {
  user: {},
  loginInProcess: false,
  loginError: false,
  registerInProcess: false,
  registerError: false,
  registerSuccess: false,
  resetPasswordInProcess: false,
  resetPasswordError: false,
  sendRegistrationInProcess: false,
  sendRegistrationSuccess: false,
  sendRegistrationError: "",
  getEmailInProcess: false,
  getEmailSuccess: false,
  getEmailError: "",
  sendpasswordResetRequestInProcess: false,
  sendpasswordResetRequestSuccess: false,
  sendpasswordResetRequestError: "",
  getpasswordResetRequestInProcess: false,
  getpasswordResetRequestSuccess: false,
  getpasswordResetRequestError: "",
  sendWaitlistInProcess: false,
  sendWaitlistSuccess: false,
  sendWaitlistError: "",
};

export default state;
