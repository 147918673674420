import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ChevronDown, ChevronUp, ShoppingCart, User, Users, Star, Award } from 'lucide-react';
import { getAllCategoryProductData } from '../../../redux/products/actions';
import { readNotesInCategory, readNotesForProduct } from '../../../redux/notes/actions';
import "../../../../assets/css/components/ChecklistItemNew.css";
import ProductSelection from './productSelection';


const isDev = process.env.NODE_ENV === 'development';
const log = (...args) => isDev && console.log(...args);

const ChecklistItemNew = ({ categoryId, categoryName, isExpanded, onToggle, checked, onProductSelect, onNoThanks, selectedProduct }) => {
  log('ChecklistItemNew rendering with props:', { categoryId, categoryName, isExpanded, checked, selectedProduct });

  const [expandedSections, setExpandedSections] = useState({
    recommendations: true,
    otherNotes: true
  });
  const [expandedProduct, setExpandedProduct] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    log('ChecklistItemNew useEffect - expandedSections:', expandedSections);
  }, [expandedSections]);

  useEffect(() => {
    log('ChecklistItemNew useEffect - expandedProduct:', expandedProduct);
  }, [expandedProduct]);

  const { data: categoryProducts, isLoading: categoryLoading, error: categoryError } = useQuery({
    queryKey: ['categoryProducts', categoryId],
    queryFn: () => {
      log('Fetching category products for categoryId:', categoryId);
      return getAllCategoryProductData(categoryId, null, null);
    },
    enabled: !!categoryId,
  });

  const { data: categoryNotes, isLoading: notesLoading, error: notesError } = useQuery({
    queryKey: ['categoryNotes', categoryId],
    queryFn: () => {
      log('Fetching category notes for categoryId:', categoryId);
      return readNotesInCategory(categoryId);
    },
    enabled: !!categoryId,
  });

  const { data: productNotes, isLoading: productNotesLoading, error: productNotesError } = useQuery({
    queryKey: ['productNotes', categoryId],
    queryFn: () => {
      log('Fetching product notes for categoryId:', categoryId);
      return readNotesForProduct(categoryId);
    },
    enabled: !!categoryId,
  });

  const categoryData = useMemo(() => {
    log('Calculating categoryData');
    if (categoryProducts && categoryProducts.length > 0) {
      return {
        uuid: categoryId,
        name: categoryProducts[0].category_name,
      };
    }
    return null;
  }, [categoryProducts, categoryId]);

  const enhancedProducts = useMemo(() => {
    log('Calculating enhancedProducts');
    return Array.isArray(categoryProducts) ? categoryProducts.map(product => ({
      ...product,
      mustHaveCount: Math.floor(Math.random() * 20) + 1,
      image: product.photo_url || `/api/placeholder/100/100`
    })) : [];
  }, [categoryProducts]);

  const enhancedCategoryNotes = useMemo(() => {
    log('Calculating enhancedCategoryNotes');
    return Array.isArray(categoryNotes) ? categoryNotes.map(note => ({
      ...note,
      source: note.name === 'You' ? 'self' : (note.name ? 'friend' : 'community'),
      recommended: Math.random() > 0.5,
      expertise: note.source === 'community' ? [
        "Pediatrician with 10 years of experience",
        "Parenting blogger and product tester",
        "Mother of 3",
        "Child development specialist"
      ][Math.floor(Math.random() * 4)] : null
    })) : [];
  }, [categoryNotes]);

  const enhancedProductNotes = useMemo(() => {
    log('Calculating enhancedProductNotes');
    return Array.isArray(productNotes) ? productNotes.map(note => ({
      ...note,
      source: note.name === 'You' ? 'self' : (note.name ? 'friend' : 'community'),
      mustHave: Math.random() > 0.7,
      expertise: note.source === 'community' ? [
        "Pediatrician with 10 years of experience",
        "Parenting blogger and product tester",
        "Mother of 3",
        "Child development specialist"
      ][Math.floor(Math.random() * 4)] : null
    })) : [];
  }, [productNotes]);

  const recommendedWithoutNote = useMemo(() => {
    log('Calculating recommendedWithoutNote');
    return enhancedCategoryNotes.filter(note => note.recommended && !note.note);
  }, [enhancedCategoryNotes]);

  const recommendationsWithNote = useMemo(() => {
    log('Calculating recommendationsWithNote');
    return enhancedCategoryNotes.filter(note => note.recommended && note.note);
  }, [enhancedCategoryNotes]);

  const otherNotes = useMemo(() => {
    log('Calculating otherNotes');
    return enhancedCategoryNotes.filter(note => !note.recommended);
  }, [enhancedCategoryNotes]);

  const toggleSection = (section) => {
    log('Toggling section:', section);
    setExpandedSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const MustHaveBadge = ({ count }) => (
    <div className="must-have-badge">
      <Award size={12} className="badge-icon" />
      <span>{count}</span>
    </div>
  );

  const getNoteIcon = (source) => {
    switch(source) {
      case 'self': return <User size={16} className="note-icon self" />;
      case 'friend': return <Users size={16} className="note-icon friend" />;
      case 'community': return <Users size={16} className="note-icon community" />;
      default: return null;
    }
  };

  const NoteItem = ({ note }) => (
    <div className="note-item">
      {getNoteIcon(note.source)}
      <div className="note-content">
        <p className="note-name">{note.name}</p>
        {note.expertise && (
          <p className="note-expertise">{note.expertise}</p>
        )}
        <p className="note-text">{note.note}</p>
      </div>
    </div>
  );

  const ExpandableSection = ({ title, items, renderItem, sectionKey }) => {
    const isExpanded = expandedSections[sectionKey];

    return (
      <div className="expandable-section">
        <div
          className="section-header"
          onClick={() => toggleSection(sectionKey)}
        >
          <h3 className="section-title">{title}</h3>
          <ChevronDown
            size={20}
            className={`chevron-icon ${isExpanded ? 'rotated' : ''}`}
          />
        </div>
        <div className={`section-content ${isExpanded ? 'expanded' : ''}`}>
          {items.map(renderItem)}
        </div>
      </div>
    );
  };

  const ProductItem = ({ product }) => {
    const isExpanded = expandedProduct === product.uuid;
    const productNotes = enhancedProductNotes.filter(note => note.productId === product.uuid);

    const endorsedBy = productNotes.filter(note => note.mustHave && !note.note);
    const endorsements = productNotes.filter(note => note.mustHave && note.note);
    const otherNotes = productNotes.filter(note => !note.mustHave);

    const productName = product.name || product.product_name;
    const imageUrl = product.photo_url || `/api/placeholder/100/100`;

    log('Rendering ProductItem:', { productName, isExpanded });

    return (
      <div className="product-item">
        <div
          className={`product-header ${isExpanded ? 'expanded' : ''}`}
          onClick={() => setExpandedProduct(isExpanded ? null : product.uuid)}
        >
          <img src={imageUrl} alt={productName} className="product-image" />
          <span className="product-name">{productName}</span>
          {product.mustHaveCount > 0 && <MustHaveBadge count={product.mustHaveCount} />}
          <ChevronDown
            size={16}
            className={`chevron-icon ${isExpanded ? 'rotated' : ''}`}
          />
        </div>
        {isExpanded && (
          <div className="product-details">
            {endorsedBy.length > 0 && (
              <div className="endorsement-section">
                <h6 className="endorsement-title">Endorsed by:</h6>
                <p className="endorsement-names">{endorsedBy.map(note => note.name).join(', ')}</p>
              </div>
            )}
            {endorsements.length > 0 && (
              <div className="endorsement-section">
                <h6 className="endorsement-title">Endorsements:</h6>
                {endorsements.map(note => <NoteItem key={note.uuid} note={note} />)}
              </div>
            )}
            {otherNotes.length > 0 && (
              <div className="endorsement-section">
                <h6 className="endorsement-title">Other notes:</h6>
                {otherNotes.map(note => <NoteItem key={note.uuid} note={note} />)}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  if (categoryLoading || notesLoading || productNotesLoading) {
    log('Rendering loading state');
    return <div className="loading">Loading...</div>;
  }

  if (categoryError || notesError || productNotesError) {
    const error = categoryError || notesError || productNotesError;
    log('Error details:', error);
    log('Error response:', error.response);
    return <div className="error">Error: {error.message || 'An unexpected error occurred'}</div>;
  }

  if (!categoryData) {
    log('Category not found');
    return <div className="not-found">Category not found</div>;
  }

  log('Rendering main ChecklistItemNew content');
  return (
    <div ref={containerRef} className="checklist-item">
    {isExpanded ? (
      <ProductSelection
        categoryId={categoryId}
        categoryName={categoryName}
        onProductSelect={onProductSelect}
        onNoThanks={onNoThanks}
        enhancedProducts={enhancedProducts}
      />
    ) : (
        <>
          {recommendedWithoutNote.length > 0 && (
            <div className="recommended-by">
              <h3 className="recommended-title">Recommended by:</h3>
              <p className="recommended-names">{recommendedWithoutNote.map(note => note.name).join(', ')}</p>
            </div>
          )}

          <div className="products-section">
            <div className="products-header">
              <h3 className="products-title">Explore the most common {categoryName}...</h3>
            </div>
            <div className="products-list">
              {enhancedProducts.map((product) => (
                <ProductItem key={product.uuid} product={product} />
              ))}
            </div>
          </div>

          <ExpandableSection
            title="Recommendations"
            items={recommendationsWithNote}
            renderItem={(note) => <NoteItem key={note.uuid} note={note} />}
            sectionKey="recommendations"
          />

          <ExpandableSection
            title="Other Notes"
            items={otherNotes}
            renderItem={(note) => <NoteItem key={note.uuid} note={note} />}
            sectionKey="otherNotes"
          />

          <div className="note-legend">
            <p><User size={16} className="legend-icon self" /> Your notes</p>
            <p><Users size={16} className="legend-icon friend" /> Friends' notes</p>
            <p><Users size={16} className="legend-icon community" /> Community notes</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ChecklistItemNew;
