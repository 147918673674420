/**
 * External Dependencies
 */
import React, { useEffect, useState, useCallback, useRef, useMemo, useLayoutEffect } from 'react';
import "../../../../assets/css/App.css";
import { useSelector, useDispatch } from 'react-redux';
import { Card, Spin } from 'antd';
import { ChevronRight, ChevronLeft, ChevronUp, UserPlus } from 'lucide-react';

/**
 * Redux Actions
 */
import { readAllTopics, readAllCategoriesInAllTopics } from '../../../redux/topics/actions.js';
import { fetchFriendLists } from '../../../redux/friends/actions';
import {
  readUserSelectedCategories,
  toggleCategoryCheck,
  loadSavedCategoryStates,
  saveCategoryState,
  saveProductState
} from '../../../redux/categories/actions.js';

/**
 * Component Imports
 */
import ChecklistsView from '../Components/ChecklistsView.js';
import InviteCard from '../Components/InviteCard.js';
import FriendCards from '../Components/FriendCards.js';

/**
 * Development Utilities
 */
const isDev = process.env.NODE_ENV === 'development';
const log = (...args) => isDev && console.log(...args);

/**
 * Color Handling Utility
 * Converts CSS variable colors to RGB format for use in animations and transitions
 */
const hexToRgb = (colorVar) => {
  // Get the actual color value from the CSS variable
  const color = getComputedStyle(document.documentElement).getPropertyValue(colorVar.replace('var(', '').replace(')', '')).trim();
  
  // Handle both hex and rgb formats
  if (color.startsWith('#')) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
  } else if (color.startsWith('rgb')) {
    return color.replace('rgb(', '').replace(')', '').trim();
  }
  return null;
};

/**
 * Performance Utility
 * Debounces frequent function calls to improve performance
 */
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * Progress Bar Component
 * Displays a visual progress indicator with customizable value and color
 * @param {number} value - Current progress value
 * @param {number} max - Maximum progress value
 * @param {string} color - CSS color for the progress bar
 */
const ProgressBar = ({ value, max, color }) => (
  <div style={{ 
    width: '100%', 
    backgroundColor: '#edf2f7', 
    borderRadius: '9999px', 
    height: '0.625rem' 
  }}>
    <div style={{
      width: `${(value / max) * 100}%`,
      backgroundColor: color,
      height: '0.625rem',
      borderRadius: '9999px'
    }}></div>
  </div>
);

/**
 * Instagram Share Icon Component
 * SVG icon for share functionality
 * @param {function} onClick - Click handler for the icon
 */
const InstagramShareIcon = ({ onClick }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="instagram-share-icon"
    onClick={onClick}
  >
    <line x1="22" y1="2" x2="11" y2="13"></line>
    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
  </svg>
);

/**
 * Home Component
 * Main container for the checklist application
 */
const Home = () => {
  /**
   * Redux State and Dispatch
   */
  const dispatch = useDispatch();
  const allTopics = useSelector(state => state.topics.allCategoriesInTopics || []);
  const checkedCategories = useSelector(state => state.categories.checkedCategories);
  const selectedProducts = useSelector(state => state.categories.selectedProducts || {});

  /**
   * Constants
   */
  const TITLE_CENTER_DURATION = 600;
  const TITLE_LEFT_POSITION = '0.5rem';

  /**
   * Refs
   */
  const containerRef = useRef(null);
  const cardBodyRef = useRef(null);
  const expandedContentRef = useRef(null);

  /**
   * Loading and Error States
   */
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Topic Selection and Navigation States
   */
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(null);
  const [expandedTopicIndex, setExpandedTopicIndex] = useState(null);
  const [navigationDirection, setNavigationDirection] = useState(null);
  const [isNavigationMode, setIsNavigationMode] = useState(false);
  const [transitionState, setTransitionState] = useState(null);
  const [previousTopicId, setPreviousTopicId] = useState(null);
  const [previousCategories, setPreviousCategories] = useState([]);


  /**
   * Animation States
   */
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationStage, setAnimationStage] = useState(0);
  const [deselectAnimationStage, setDeselectAnimationStage] = useState(0);
  const [isDeselecting, setIsDeselecting] = useState(false);
  const [deselectedItemRepositioned, setDeselectedItemRepositioned] = useState(false);
  const [repositioningIndex, setRepositioningIndex] = useState(null);
  const [headerAnimationState, setHeaderAnimationState] = useState('normal');

  /**
   * Category and Product States
   */
  const [expandedCategoryId, setExpandedCategoryId] = useState(null);
  const [selectedProductNames, setSelectedProductNames] = useState({});

  /**
   * Invite Card and Share States
   */
  const [isInviteCardVisible, setIsInviteCardVisible] = useState(false);
  const [inviteCardType, setInviteCardType] = useState('friends');
  const [isShareViewActive, setIsShareViewActive] = useState(false);
  const [shouldUnmount, setShouldUnmount] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);

  /**
   * View friends lists states
   */
  const [isViewingFriend, setIsViewingFriend] = useState(false);
  const [currentUserIndex, setCurrentUserIndex] = useState(0);
    const friendLists = useSelector(state => {
      const lists = state.friends?.friendLists;
      return Array.isArray(lists) ? lists : [];
    });

  /**
   * Window Resize Effect and Scroll Management
   * Forces re-render on window resize and manages page scroll
   */
  useEffect(() => {
    // Handle resize
    const handleResize = () => setIsAnimating(prev => prev);
    window.addEventListener('resize', handleResize);

    // Disable page scroll
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    
    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      // Re-enable page scroll
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    };
  }, []);

  /**
   * Data Fetching Effect
   * Loads initial data and user states
   */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        await Promise.all([
          dispatch(readAllTopics()),
          dispatch(readAllCategoriesInAllTopics()),
          dispatch(readUserSelectedCategories()),
          dispatch(loadSavedCategoryStates())
        ]);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  /**
   * Layout Management Effects
   */
  // Scroll position management
  useEffect(() => {
    const cardBody = cardBodyRef.current;
    if (cardBody) {
      if (!isInviteCardVisible) {
        cardBody.scrollTop = 0;
      }
    }
  }, [isInviteCardVisible]);

  useEffect(() => {
    dispatch(fetchFriendLists());
  }, [dispatch]);

  // Invite section height adjustment
  useLayoutEffect(() => {
    if (isInviteCardVisible && cardBodyRef.current) {
      const inviteSection = cardBodyRef.current.querySelector('.invite-section');
      if (inviteSection) {
        const height = inviteSection.scrollHeight;
        cardBodyRef.current.style.height = `${height}px`;
      }
    }
  }, [isInviteCardVisible]);


  /**
   * Window Resize Effect
   * Forces re-render on window resize for responsive adjustments
   */
  useEffect(() => {
    const handleResize = () => setIsAnimating(prev => prev);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * Transition Management Effect
   * Handles mount/unmount transitions with cleanup
   */
  useEffect(() => {
    const timeoutIds = [];
    
    if (isTransitioning) {
      const transitionTimeout = setTimeout(() => {
        setIsTransitioning(false);
        if (!isInviteCardVisible) {
          const unmountTimeout = setTimeout(() => {
            setShouldUnmount(true);
          }, 50);
          timeoutIds.push(unmountTimeout);
        }
      }, 500);
      timeoutIds.push(transitionTimeout);
    }

    return () => {
      timeoutIds.forEach(id => clearTimeout(id));
    };
  }, [isTransitioning, isInviteCardVisible]);

  /**
   * Progress Calculation Utilities
   */
  const calculateProgress = useCallback((topic) => {
    if (!topic.categories || topic.categories.length === 0) return 0;
    const completedCount = topic.categories.filter(category => checkedCategories[category.uuid]).length;
    return (completedCount / topic.categories.length) * 100;
  }, [checkedCategories]);
  
  // Combine current user with friends list
  const allUsers = useMemo(() => {
    const currentUser = { id: 'you', name: 'Your', topics: allTopics };
    const friendUsers = friendLists?.map(friend => ({
      id: friend.uuid,
      name: `${friend.first_name}'s`,
      topics: allTopics
    })) || [];
    return [currentUser, ...friendUsers];
  }, [friendLists, allTopics]);  

  const getProgressColor = (index) => {
    const colors = [
      'var(--progress-color-1)',
      'var(--progress-color-2)',
      'var(--progress-color-3)',
      'var(--progress-color-4)',
      'var(--progress-color-5)',
      'var(--progress-color-6)',
      'var(--progress-color-7)',
      'var(--progress-color-8)',
      'var(--progress-color-9)',
      'var(--progress-color-10)',
      'var(--progress-color-11)',
      'var(--progress-color-12)'
    ];
    return colors[index % colors.length];
  };

  /**
   * Navigation Handlers
   */
  const handleNavigateToChecklist = useCallback((topicIndex) => {
    if (isAnimating) return;
  
    const currentTopicId = expandedTopicIndex !== null ? allTopics[expandedTopicIndex].id : null;
    const nextTopicId = allTopics[topicIndex].id;
    
    setIsAnimating(true);
    setPreviousTopicId(currentTopicId);
    
    // Mount new content 
    setSelectedTopicIndex(topicIndex);
    setTransitionState({ 
      phase: 'mount',
      currentTopicId,
      nextTopicId,
    });
  
    // Start transitions after mount
    requestAnimationFrame(() => {
      setTransitionState(prev => ({
        ...prev,
        phase: 'transition'
      }));
  
      // Complete transition
      setTimeout(() => {
        setExpandedTopicIndex(topicIndex);
        setTransitionState(null);
        setIsAnimating(false);
      }, 300);
    });
  }, [isAnimating, expandedTopicIndex, allTopics]);

  const handleNavigateTopic = useCallback((direction, e) => {
    e.stopPropagation();
    if (allTopics.length <= 1) return;

    const currentIndex = selectedTopicIndex !== null ? selectedTopicIndex : 0;
    const newIndex = direction === 'next'
      ? (currentIndex + 1) % allTopics.length
      : (currentIndex - 1 + allTopics.length) % allTopics.length;

    setSelectedTopicIndex(newIndex);
    setExpandedTopicIndex(newIndex);
    setNavigationDirection(direction);
    setIsNavigationMode(true);
    setIsDeselecting(false);

    setIsAnimating(false);
    setAnimationStage(5);

    console.log(`Navigation completed: direction = ${direction}, newIndex = ${newIndex}`);
  }, [allTopics.length, selectedTopicIndex]);

/**
 * Navigation Handlers
 */
const handleNavigateUser = useCallback((direction) => {
  const newIndex = direction === 'next' 
    ? (currentUserIndex + 1) % allUsers.length
    : (currentUserIndex - 1 + allUsers.length) % allUsers.length;
  setCurrentUserIndex(newIndex);
  setIsViewingFriend(allUsers[newIndex].id !== 'you');
  
  if (allUsers[newIndex].id !== 'you') {
    dispatch(readUserSelectedCategories(allUsers[newIndex].id));
  } else {
    dispatch(readUserSelectedCategories(null));
  }
}, [currentUserIndex, allUsers, dispatch]);
  
  /**
   * Topic Selection/Deselection Handlers
   */

  const handleDeselectAllTopics = useCallback(() => {
    if (selectedTopicIndex !== null) {
      setPreviousCategories(allTopics[selectedTopicIndex]?.categories || []);
      setIsDeselecting(true);
      setIsAnimating(true);
      setDeselectAnimationStage(1);
      
      setTimeout(() => {
        setDeselectAnimationStage(2);
        
        setTimeout(() => {
          setDeselectAnimationStage(3);
          setTimeout(() => {
            setSelectedTopicIndex(null);
            setExpandedTopicIndex(null);
            setIsAnimating(false);
            setIsDeselecting(false);
            setDeselectAnimationStage(0);
            setPreviousCategories([]);
          }, 350);
        }, 350);
      }, 500);
    }
  }, [selectedTopicIndex, allTopics]);

  /**
   * Category and Product Handlers
   */
  const handleCategoryExpand = useCallback((categoryId) => {
    setExpandedCategoryId(prevId => {
      const newId = prevId === categoryId ? null : categoryId;
      
      // Wait for next frame to ensure DOM is updated
      requestAnimationFrame(() => {
        const element = document.getElementById(`category-content-${categoryId}`);
        const homeContainer = document.querySelector('.home-container');
        
        if (element && homeContainer && newId) {
          const elementRect = element.getBoundingClientRect();
          const containerRect = homeContainer.getBoundingClientRect();
          const relativeTop = elementRect.top - containerRect.top;
          
          // Scroll the home-container instead of the cardBody
          homeContainer.scrollBy({
            top: relativeTop - 30, // Account for fixed header (135px margin-top)
            behavior: 'smooth'
          });
        }
      });
      
      return newId;
    });
  }, []);

  const handleProductSelect = useCallback((categoryId, productId, productName) => {
      // We only need to handle category expansion state here
      handleCategoryExpand(null);
  }, [handleCategoryExpand]);

  const handleNoThanks = useCallback((categoryId) => {
    dispatch(saveProductState(categoryId, 'none'));
    dispatch(toggleCategoryCheck(categoryId, true));
    dispatch(saveCategoryState(categoryId, true));
    setSelectedProductNames(prev => ({...prev, [categoryId]: 'Select a product'}));
    handleCategoryExpand(null);
  }, [dispatch, handleCategoryExpand]);

  /**
   * Transition and UI Event Handlers
   */
  const handleTransitionAndUnmount = useCallback(() => {
    setIsTransitioning(true);
    setIsInviteCardVisible(false);
    setIsShareViewActive(false);

    setTimeout(() => {
      setIsTransitioning(false);
      setTimeout(() => {
        setShouldUnmount(true);
      }, 50);
    }, 500);
  }, []);

  const handleShareIconClick = useCallback(() => {
    setShouldUnmount(false);
    setIsTransitioning(true);
    setIsShareViewActive(true);
    setIsInviteCardVisible(true);
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 500);
  }, []);

  const handleCloseInviteCard = useCallback(() => {
    handleTransitionAndUnmount();
  }, [handleTransitionAndUnmount]);

  const handleInviteSent = useCallback(() => {
    handleTransitionAndUnmount();
  }, [handleTransitionAndUnmount]);

  const handleBackFromShare = useCallback(() => {
    handleTransitionAndUnmount();
  }, [handleTransitionAndUnmount]);

  const handleTitleClick = useCallback(() => {
    if (isInviteCardVisible) {
      handleTransitionAndUnmount();
    } else {
      handleDeselectAllTopics();
    }
  }, [isInviteCardVisible, handleTransitionAndUnmount, handleDeselectAllTopics]);

  /**
   * Main Item Style Generator
   * Handles item appearance based on selection and animation states
   */
  const getItemStyle = useCallback((index) => {
    const isSelected = index === selectedTopicIndex;
    const wasOnRight = selectedTopicIndex !== null && index > selectedTopicIndex;
    const isMobile = window.innerWidth < 768;

    const baseStyle = {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '0.375rem',
      cursor: expandedTopicIndex !== null ? 'default' : isAnimating ? 'default' : 'pointer',
      position: 'absolute',
      left: 0,
      right: 0,
      marginTop: 10,
      height: '40px',
      maxHeight: '40px',
      opacity: 1,
      zIndex: isSelected ? 2 : 1,
      overflow: 'visible',
      transition: 'background-color 0.2s ease-in-out',
    };

    // Handle deselection animation sequence
    if (isDeselecting) {
      return {
        ...baseStyle,
        top: (() => {
          if (deselectAnimationStage <= 2) {
            return isSelected ? 0 : `${index * 50}px`;
          }
          return `${index * 50}px`;
        })(),
        transform: (() => {
          if (deselectAnimationStage === 1) {
            if (wasOnRight) {
              return 'translateX(-100%)';
            }
            return 'translateX(-100%)';
          }
          switch (deselectAnimationStage) {
            case 2:
            case 3:
              return 'translateX(-100%)';
            case 4:
              return 'translateX(0)';
            default:
              return 'translateX(0)';
          }
        })(),
        transition: (() => {
          if (deselectAnimationStage === 1 && wasOnRight) {
            return 'none';
          }
          switch (deselectAnimationStage) {
            case 1:
              return 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out';
            case 2:
            case 3:
              return 'none';
            case 4:
              return 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out';
            default:
              return 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out';
          }
        })(),
      };
    }

    if (selectedTopicIndex === null) {
      return {
        ...baseStyle,
        top: `${index * 50}px`,
        transform: 'translateX(0)',
        transition: 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out',
      };
    }

    if (isSelected) {
      return {
        ...baseStyle,
        top: animationStage >= 3 ? '0' : `${index * 50}px`,
        transform: 'translateX(0)',
        height: animationStage >= 4 ? 'auto' : '40px',
        maxHeight: animationStage >= 4 ? 'none' : '40px',
        transition: 'transform 0.3s ease-in-out, top 0.3s ease-in-out, background-color 0.2s ease-in-out',
      };
    }

    // Handle mobile vs desktop styles
    if (isMobile) {
      const direction = index < selectedTopicIndex ? -1 : 1;
      const shouldHide = animationStage >= 5;
      
      return {
        ...baseStyle,
        top: `${index * 50}px`,
        transform: `translateX(${(animationStage >= 1 && animationStage <= 6) ? direction * 110 : 0}%)`,
        transition: 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out',
        display: shouldHide ? 'none' : 'flex',
      };
    } else {
      return {
        ...baseStyle,
        top: `${index * 50}px`,
        transform: `translateX(${(animationStage >= 1 && animationStage <= 6) ? -85 : 0}%)`,
        transition: 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out, opacity 0.3s ease-in-out',
        opacity: (animationStage >= 1 && animationStage <= 6) ? 0.5 : 1,
        pointerEvents: (animationStage >= 1 && animationStage <= 6) ? 'none' : 'auto',
      };
    }
  }, [selectedTopicIndex, expandedTopicIndex, animationStage, isDeselecting, deselectAnimationStage, isAnimating]);

  /**
   * Inner Container Style Generator
   * Handles container background and transitions
   */
  const getInnerContainerStyle = useCallback((index, progressColor) => {
    const isSelected = index === selectedTopicIndex;
    if (isDeselecting) {
      return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '40px',
        position: 'relative',
        backgroundColor: isSelected && deselectAnimationStage < 2
          ? `rgba(${hexToRgb(progressColor)}, 1)`
          : 'transparent',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        transition: 'none',
      };
    }

    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '40px',
      position: 'relative',
      backgroundColor: isSelected
        ? `rgba(${hexToRgb(progressColor)}, ${(animationStage - 1) / 4})`
        : 'transparent',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      transition: 'all 0.3s ease-in-out',
    };
  }, [selectedTopicIndex, animationStage, isDeselecting, deselectAnimationStage]);

  /**
   * Header Style Generators
   */
  const getHeaderStyle = useCallback(() => {
    const isItemSelected = selectedTopicIndex !== null;
    const baseStyle = {
      backgroundColor: 'var(--color-cream)',
      color: 'var(--text-primary)',
      transition: 'background-color 0.3s ease-in-out'
    };

    if (isDeselecting) {
      return {
        ...baseStyle,
        backgroundColor: deselectAnimationStage >= 2 ? 'var(--color-cream)' : 'var(--color-faded)'
      };
    }

    if (isItemSelected && animationStage >= 3) {
      return {
        ...baseStyle,
        backgroundColor: 'var(--color-faded)'
      };
    }

    return baseStyle;
  }, [selectedTopicIndex, isDeselecting, deselectAnimationStage, animationStage]);

  const getTitleStyle = useCallback((index) => {
    const isSelected = index === selectedTopicIndex;

    const baseStyle = {
      position: 'absolute',
      top: '50%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      zIndex: 10,
    };

    const normalStyle = {
      ...baseStyle,
      left: TITLE_LEFT_POSITION,
      width: 'calc(100% - 1rem)',
      transform: 'translateY(-50%)',
      fontSize: '1rem',
      fontWeight: 'normal',
      color: 'inherit',
      textAlign: 'left',
      transition: isDeselecting ? 'none' : 'transform 0.3s ease-in-out, left 0.3s ease-in-out',
    };

    const selectedStyle = {
      ...baseStyle,
      left: '50%',
      width: 'auto',
      transform: 'translate(-50%, -50%)',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: 'white',
      textAlign: 'center',
      transition: 'transform 0.3s ease-in-out, left 0.3s ease-in-out',
    };

    if (isDeselecting) {
      if (deselectAnimationStage >= 2) {
        return normalStyle;
      }
      return selectedStyle;
    }

    return isSelected ? selectedStyle : normalStyle;
  }, [selectedTopicIndex, isDeselecting, deselectAnimationStage, TITLE_LEFT_POSITION]);

  /**
   * Progress Style Generator
   */
  const getProgressStyle = useCallback((index) => {
    const isSelected = index === selectedTopicIndex;
    return {
      position: 'absolute',
      right: '2rem',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      alignItems: 'center',
      opacity: (isSelected && !isDeselecting) ||
        (isDeselecting && deselectAnimationStage < 5) ? 0 : 1,
      transition: 'opacity 0.3s ease-in-out',
    };
  }, [selectedTopicIndex, isDeselecting, deselectAnimationStage]);

  /**
   * Memoized Components
   */
  const memoizedChecklistsView = useMemo(() => {
    const topicId = isDeselecting ? previousTopicId : 
      (expandedTopicIndex !== null ? allTopics[selectedTopicIndex]?.id : null);
   
    return (
      <ChecklistsView
        topicId={topicId}
        isEmbedded={true}
        onNavigate={() => {}}
        onCategoryExpand={handleCategoryExpand}
        expandedCategoryId={expandedCategoryId}
        onAfterProductSelect={() => handleCategoryExpand(null)}
        isDeselecting={isDeselecting}
        previousCategories={previousCategories}
        isViewingFriend={isViewingFriend}
      />
    );
   }, [expandedTopicIndex, allTopics, handleCategoryExpand, expandedCategoryId, isDeselecting, selectedTopicIndex, previousTopicId, previousCategories]);

   const getExpandedContentStyle = (isExpanded, transitionState) => ({
    backgroundColor: 'white',
    padding: isExpanded ? '1rem' : 0,
    width: '100%',
    position: 'relative',
    marginTop: '1px',
    maxHeight: isExpanded ? '2000px' : '0',
    opacity: transitionState?.phase === 'mount' ? 0 : 1,
    transform: `scale(${isExpanded ? 1 : 0.98})`,
    overflow: 'hidden',
    transition: 'all 0.3s ease-in-out',
    visibility: transitionState?.phase === 'mount' || isExpanded ? 'visible' : 'hidden'
  });
  
  const getInnerContentStyle = (isExpanded, transitionState) => ({
    opacity: transitionState?.phase === 'mount' ? 0 : 1,
    transform: `translateY(${isExpanded ? 0 : -10}px)`,
    transition: 'all 0.3s ease-in-out',
    pointerEvents: isExpanded ? 'auto' : 'none'
  });

  const logExpandedContent = (isExpanded, deselectAnimationStage) => {
    console.log('Expanded Content:', {
      isExpanded,
      deselectAnimationStage,
      elementExists: expandedContentRef.current !== null,
      height: expandedContentRef.current?.offsetHeight,
      scrollHeight: expandedContentRef.current?.scrollHeight,
      style: getExpandedContentStyle(isExpanded, deselectAnimationStage)
    });
  };
  

  /**
   * Header Title Generator
   * Complex header with multiple states and transitions
   */
  const getHeaderTitle = useCallback(() => {
    const isItemSelected = selectedTopicIndex !== null;
    const showBackState = isItemSelected && animationStage >= 3 && !isDeselecting;
    const selectedTopicName = isItemSelected ? allTopics[selectedTopicIndex]?.name : '';
  
    return (
      <div className="card-title">
        <div style={{ width: '24px', display: showBackState ? 'none' : 'block' }} />
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            transition: 'justify-content 0.3s ease-in-out',
            marginLeft: showBackState ? '-24px' : '0',
            overflow: 'hidden',
            position: 'relative',
            height: '32px',
          }}
        >
          {/* "Your Checklists" text */}
          <span
            style={{
              position: 'absolute',
              left: '50%',
              transform: `translateX(${
                isInviteCardVisible ? '-150%' :
                showBackState ? '-150%' :
                '-50%'
              })`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              opacity: !showBackState && !isInviteCardVisible ? 1 : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '18px',
              fontWeight: '500',
              cursor: 'default',
              whiteSpace: 'nowrap',
              pointerEvents: !showBackState && !isInviteCardVisible ? 'auto' : 'none'
            }}
          >
            {allUsers[currentUserIndex]?.name || 'Your'} Checklists
          </span>
  
          {/* "Back to checklists" button */}
          <div
            className="back-button"
            onClick={handleTitleClick}
            style={{
              position: 'absolute',
              left: 0,
              transform: `translateX(${
                isInviteCardVisible ? '-150%' :
                showBackState ? '0' :
                '-150%'
              })`,
              opacity: !isInviteCardVisible && showBackState ? 1 : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '14px',
              fontWeight: '400',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              padding: '0.5rem 0.75rem',
              pointerEvents: !isInviteCardVisible && showBackState ? 'auto' : 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              borderRadius: '6px',
            }}
          >
            <ChevronLeft size={18} />
            Back to checklists
          </div>
  
          {/* "Back to [topic/checklists]" button for share view */}
          <div
            className="back-button"
            onClick={handleTitleClick}
            style={{
              position: 'absolute',
              left: '50%',
              transform: `translateX(-50%) translateX(${isInviteCardVisible ? '0' : '150%'})`,
              opacity: isInviteCardVisible ? 1 : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '14px',
              fontWeight: '400',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              padding: '0.5rem 0.75rem',
              pointerEvents: isInviteCardVisible ? 'auto' : 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              borderRadius: '6px',
            }}
          >
            <ChevronUp size={18} />
            Back to {isItemSelected ? selectedTopicName : 'your checklists'}
          </div>
        </div>
        <div style={{ 
          width: '24px', 
          marginRight: '10px',
          display: 'flex',
          alignItems: 'center',
          height: '32px'
        }}>
          <InstagramShareIcon onClick={handleShareIconClick}/>
        </div>
      </div>
    );
  }, [
    selectedTopicIndex,
    animationStage,
    isDeselecting,
    handleTitleClick,
    handleShareIconClick,
    isInviteCardVisible,
    allTopics
  ]);

  /**
   * Loading and Error States
   */
  if (isLoading) {
    return (
      <div className="loading-container">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  console.log('Render called with states:', {
    selectedTopicIndex,
    isDeselecting,
    deselectAnimationStage,
    animationStage
  });

  /**
   * Main Component Render
   */
  return (
    <>
      <div className="fixed-header" style={{
        position: 'fixed',
        backgroundColor: "#ffffe3",
        zIndex: 10,
        padding: '5px',
        paddingRight: '1em',
        border: '1px solid #edf2f7',
        borderRadius: '10px',
        width: "calc(100% - 30px)",
        top: "65px",
        left: "15px",
        height: "50px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <button 
          onClick={() => handleNavigateUser('prev')}
          style={{
            padding: '8px',
            borderRadius: '50%',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            border: 'none'
          }}
        >
          <ChevronLeft size={20} />
        </button>

        <h1 style={{
          margin: 0,
          fontSize: '1.25rem',
          fontWeight: '500',
        }}>
          {allUsers[currentUserIndex].name} Checklists
        </h1>

        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          <button 
            onClick={() => handleNavigateUser('next')}
            style={{
              padding: '8px',
              borderRadius: '50%',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 'none'
            }}
          >
            <ChevronRight size={20} />
          </button>
          <button 
              onClick={handleShareIconClick}
              style={{
                padding: '8px',
                backgroundColor: 'rgb(243, 232, 255)',
                borderRadius: '9999px',
                cursor: 'pointer',
                marginRight: '5px',
                border: '#e2e2e2',
              }}
            >
              <UserPlus 
                onClick={() => {
                  handleShareIconClick();
                  document.getElementById('invite-section')?.scrollIntoView({ behavior: 'smooth' });
                }}
                style={{
                  padding: '2px',
                  backgroundColor: 'rgb(243, 232, 255)',
                  borderRadius: '9999px',
                  cursor: 'pointer',
                  border: 'none',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                size={16} 
              />
            </button>
          </div>
      </div>

    <div className="home-container" style={{
      position: "fixed",
      top: "90px",
      bottom: 0,
      left: 0,
      right: 0,
      overflowY: "auto",
      padding: "15px",
      backgroundColor: "var(--background-primary)",
      paddingBottom: "200px"
    }}>   
        <div>
          <Card
            bodyStyle={{
              padding: '0rem',
              height: 'auto',
              overflow: 'visible',
            }}
          >
            <div style={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              marginTop: '25px'
            }}>
              {/* Topics List */}
              <div className="checklist-wrapper">
                {allTopics.length > 0 ? (
                  <div className="topics-list" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: '100%',
                    height: '100%',
                    overflowY: 'auto',
                    paddingBottom: '20px' // Add padding at bottom for better visibility
                  }}>
                    {allTopics.map((topic, index) => {
                      const progress = calculateProgress(topic);
                      const isSelected = index === selectedTopicIndex;
                      const isExpanded = index === expandedTopicIndex;
                      const progressColor = getProgressColor(index);

                      return (
                        <div
                          key={topic.id}
                          className="topic-item"
                          onClick={() => handleNavigateToChecklist(index)}
                          style={{
                            backgroundColor: isSelected 
                              ? `rgba(${hexToRgb(progressColor)}, 1)`  // Changed this line
                              : 'transparent',
                            
                            transition: 'all 0.3s ease-in-out',
                            cursor: expandedTopicIndex === null ? 'pointer' : 'default',
                            width: '100%',
                            opacity: !isSelected && expandedTopicIndex !== null ? 0.5 : 1,  // Added this line
                            boxShadow: isSelected ? '0 4px 6px -1px rgba(0, 0, 0, 0.1)' : 'none',  // Added this line
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          {/* Topic Header */}
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '10px',
                            height: '40px',
                            width: '100%'
                          }}>
                            <span style={{
                              fontSize: isExpanded ? '1.25rem' : '1rem',
                              fontWeight: isExpanded ? 'bold' : 'normal',
                              transition: 'all 0.3s ease-in-out'
                            }}>
                              {topic.name}
                            </span>
                            {(
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem'
                              }}>
                                <div className="progress-bar" style={{ 
                                  width: '100px',
                                  backgroundColor: isSelected ? 'rgba(255,255,255,0.3)' : '#edf2f7',
                                }}>
                                  <div
                                    className="progress-fill"
                                    style={{
                                      width: `${progress}%`,
                                      backgroundColor: isSelected ? 'white' : progressColor,
                                      height: '0.625rem',
                                      borderRadius: '9999px'
                                    }}
                                  />
                                </div>
                                <span style={{ 
                                  color: isSelected ? 'white' : '#718096', 
                                  fontSize: '0.875rem' 
                                }}>
                                  {`${Math.round(progress)}%`}
                                </span>
                              </div>
                            )}
                          </div>


{/* Expanded Content */}
<div 
  className="expanded-content" 
  ref={expandedContentRef}
  onClick={(e) => e.stopPropagation()} 
  style={getExpandedContentStyle(isExpanded, deselectAnimationStage)} 
>
 <div style={getInnerContentStyle(isExpanded, deselectAnimationStage)}>
    {memoizedChecklistsView}
  </div>
</div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px'
                  }}>
                    <Spin size="large" />
                  </div>
                )}
              </div>
  
              {/* Invite Card Section */}
              <div 
                id="invite-section"
                className="invite-section" 
                style={{
                  backgroundColor: '#f3f4f6',
                  padding: '1rem',
                  borderRadius: '10px',
                  scrollMarginTop: '120px'
                }}
              >
                <InviteCard
                  type={inviteCardType}
                  onClose={handleCloseInviteCard}
                  onInvite={handleInviteSent}
                />
                <div style={{ marginTop: '1rem' }}>
                  <FriendCards />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
  
      <style jsx>{`

        .home-container::-webkit-scrollbar {
            width: 6px;
        }

        .home-container::-webkit-scrollbar-track {
            background: #fafafa;
        }

        .home-container::-webkit-scrollbar-thumb {
            background: #dbdbdb;
            border-radius: 3px;
        }

        .home-container:hover::-webkit-scrollbar-thumb {
            background: #b5b5b5;

        .progress-bar {
          background-color: #edf2f7;
          border-radius: 9999px;
          height: 0.625rem;
        }
  
        .progress-fill {
          transition: width 0.3s ease-in-out;
        }
  
        .topic-item:hover {
          background-color: rgba(181, 212, 255, 0.1);
        }
      `}</style>
    </>
  );
};

export default Home;