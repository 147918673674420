import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button } from 'antd';
import pregnantHeartVideo1 from '../../assets/login/pregnantHeartVideo1.mp4';
import pregnantHeartVideo2 from '../../assets/login/pregnantHeartVideo2.mp4';
import pregnantHeartVideo3 from '../../assets/login/pregnantHeartVideo3.mp4';
import pregnantHeartVideo4 from '../../assets/login/pregnantHeartVideo4.mp4';

export default function PrivacySection({ toggleRegistrationModalVisibility }) {
  const [isVisible, setIsVisible] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const sectionRef = useRef(null);

  const videoRefs = [
    { src: pregnantHeartVideo1, ref: useRef() },
    { src: pregnantHeartVideo2, ref: useRef() },
    { src: pregnantHeartVideo3, ref: useRef() },
    { src: pregnantHeartVideo4, ref: useRef() }
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting && videoReady) {
          videoRefs[currentVideoIndex].ref.current?.play().catch(error => {
            console.log('Auto-play prevented:', error);
            setShowPlayButton(true);
          });
        }
      },
      {
        threshold: [0.3],
        rootMargin: '-50px 0px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [currentVideoIndex, videoReady]);

  useEffect(() => {
    setVideoReady(true);
  }, []);

  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % videoRefs.length;
      if (videoReady) {
        videoRefs[nextIndex].ref.current?.play().catch(error => {
          console.log('Auto-play prevented:', error);
          if (isMobile) {
            setShowPlayButton(true);
          }
        });
      }
      return nextIndex;
    });
  };

  const handleVideoPlay = () => {
    const currentVideo = videoRefs[currentVideoIndex].ref.current;
    if (currentVideo) {
      currentVideo.play().catch(error => {
        console.log('Play prevented:', error);
        setShowPlayButton(true);
      });
    }
    setShowPlayButton(false);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        videoRefs.forEach(video => {
          if (video.ref.current) {
            video.ref.current.pause();
          }
        });
      } else {
        const currentVideo = videoRefs[currentVideoIndex].ref.current;
        if (currentVideo && videoReady) {
          if (currentVideo.ended) {
            currentVideo.currentTime = 0;
          }
          if (isMobile) {
            currentVideo.currentTime = 0;
            setShowPlayButton(true);
          } else {
            currentVideo.play().catch(error => {
              console.log('Auto-play prevented:', error);
              setShowPlayButton(true);
            });
          }
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [currentVideoIndex, videoReady, isMobile]);

  return (
    <Row
      ref={sectionRef}
      justify="center"
      align="middle"
      style={{
        minHeight: '700px',
        height: '100vh',
        width: '100%',
        margin: 0,
        position: 'relative',
        background: 'white',
        padding: '50px 0',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Col
        xs={{ span: 24, order: 1 }}
        md={{ span: 12, order: 1 }}
        style={{
          paddingLeft: isMobile ? '24px' : '64px',
          paddingRight: isMobile ? '24px' : '48px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: isMobile ? 'center' : 'flex-start',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateX(0)' : 'translateX(-20px)',
          transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        }}
      >
        <div style={{
          maxWidth: isMobile ? '100%' : '480px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: isMobile ? 'center' : 'flex-start'
        }}>
          <h2
            style={{
              fontSize: '32px',
              fontWeight: '600',
              color: '#1F1F1F',
              marginBottom: '16px',
              textAlign: isMobile ? 'center' : 'left',
            }}
          >
            When you use <strong>me</strong>search,<br />
            your research stays private
          </h2>
          <div
            style={{
              fontSize: '20px',
              color: '#666666',
              background: '#FFF7CC',
              padding: '4px 8px',
              borderRadius: '4px',
              marginBottom: '16px',
              textAlign: 'center',
              display: isMobile ? 'block' : 'inline-block',
              width: isMobile ? 'fit-content' : 'auto'
            }}
          >
            Your trust is our priority.
          </div>
          <p
            style={{
              fontSize: '20px',
              color: '#666666',
              lineHeight: '1.4',
              margin: '0',
              textAlign: isMobile ? 'center' : 'left',
            }}
          >
            That means keeping your data safe, secure, and private. We will <strong>never</strong> share your data without your explicit consent.
          </p>

          <Button
            onClick={toggleRegistrationModalVisibility}
            style={{
              background: '#6B4EFF',
              color: 'white',
              border: 'none',
              borderRadius: '20px',
              height: '40px',
              padding: '0 24px',
              marginTop: '32px',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateX(0)' : 'translateX(-20px)',
              transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
            }}
          >
            Sign up
          </Button>
        </div>
      </Col>

      <Col
        xs={{ span: 24, order: 2 }}
        md={{ span: 12, order: 2 }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: isMobile ? '400px' : '600px',
          marginTop: isMobile ? '32px' : '0',
          marginBottom: isMobile ? '32px' : '0',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateX(0)' : 'translateX(20px)',
          transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        }}
      >
        <div style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          {videoRefs.map((video, index) => (
            <video
              key={index}
              ref={video.ref}
              autoPlay={false}
              muted
              playsInline
              onEnded={handleVideoEnd}
              style={{
                width: '100%',
                height: '100%',
                objectFit: isMobile ? 'cover' : 'contain',
                display: currentVideoIndex === index ? 'block' : 'none',
              }}
            >
              <source src={video.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ))}
          {showPlayButton && (
            <button
              onClick={handleVideoPlay}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: 'rgba(107, 78, 255, 0.8)',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '24px',
                cursor: 'pointer'
              }}
            >
              ▶
            </button>
          )}
        </div>
      </Col>
    </Row>
  );
}