import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import PropTypes from 'prop-types';
import { useVideoSequence } from './HeroVideo';
import babyVideo from '../../assets/login/baby_video.mp4';
import cribMobileVideo from '../../assets/login/crib_mobile.mp4';
import babyWithToysVideo from '../../assets/login/baby_with_toys.mp4';
import babyFootVideo from '../../assets/login/baby_foot_video.mp4';

const ActionButtons = ({ toggleRegistrationModalVisibility, toggleLoginModalVisibility }) => (
  <div style={{
    display: 'flex',
    gap: '12px',
    justifyContent: 'center',
    transition: 'opacity 0.5s ease-in',
    marginTop: '20px',
  }}>
    <Button
      onClick={toggleRegistrationModalVisibility}
      style={{
        background: '#6B4EFF',
        color: 'white',
        border: 'none',
        borderRadius: '20px',
        height: '40px',
        padding: '0 24px',
      }}
    >
      Sign up
    </Button>
  </div>
);

ActionButtons.propTypes = {
  toggleRegistrationModalVisibility: PropTypes.func.isRequired,
  toggleLoginModalVisibility: PropTypes.func.isRequired,
};

const Hero = ({ toggleRegistrationModalVisibility, toggleLoginModalVisibility }) => {
  const [showTitle, setShowTitle] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [showGetStarted, setShowGetStarted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showVideo, setShowVideo] = useState(false);

  const {
    currentVideoIndex,
    firstVideo,
    secondVideo,
    thirdVideo,
    fourthVideo,
    handleFirstVideoEnd,
    handleSecondVideoEnd,
    handleThirdVideoEnd,
    handleFourthVideoEnd
  } = useVideoSequence(showVideo);

  useEffect(() => {
    const titleTimer = setTimeout(() => setShowTitle(true), 0);
    const subtitleTimer = setTimeout(() => setShowSubtitle(true), 1000);
    const getStartedTimer = setTimeout(() => setShowGetStarted(true), 3000);
    const videoTimer = setTimeout(() => setShowVideo(true), 5000);

    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(titleTimer);
      clearTimeout(subtitleTimer);
      clearTimeout(getStartedTimer);
      clearTimeout(videoTimer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <Row
        type="flex"
        justify="center"
        align="middle"
        className="heroContainer"
        style={{
          position: 'relative',
          margin: 0,
          minHeight: isMobile ? 'calc(100vh - 72px)' : '70vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 10, order: 1 }}
          lg={{ span: 10, order: 1 }}
          style={{
            padding: isMobile ? '0px 24px 32px' : '32px 24px',
            textAlign: 'center',
            zIndex: 2,
          }}
        >
          <div style={{
            fontSize: isMobile ? '24px' : '32px',
            fontWeight: '600',
            marginBottom: '16px',
            marginTop: isMobile ? '25px' : '0px',
            color: '#1F1F1F',
            opacity: showTitle ? 1 : 0,
            transition: 'opacity 0.5s ease-in'
          }}>
            Your baby deserves the best.
          </div>
          <p style={{
            fontSize: '20px',
            color: '#666666',
            margin: '0',
            lineHeight: '1.4',
            opacity: showSubtitle ? 1 : 0,
            transition: 'opacity 0.5s ease-in',
            marginBottom: isMobile ? '0px' : '16px',
          }}>
            The best for <b>your</b> family, that is.
          </p>
          {!isMobile && (
            <div className='welcomeCard' style={{paddingLeft: '15%', paddingRight: '15%'}}>
              <div style={{
                background: '#fafafa',
                padding: 25,
                borderRadius: 10,
                boxShadow: '2px 2px 2px 2px #f2f2f2',
                opacity: showGetStarted ? 1 : 0,
                transition: 'opacity 0.5s ease-in',
              }}>
                <p style={{
                  fontSize: '20px',
                  color: '#666666',
                  margin: '0',
                  lineHeight: '1.4',
                }}>
                  Get organized and confident with an app built for first-time parents.
                </p>
                <ActionButtons 
                  toggleRegistrationModalVisibility={toggleRegistrationModalVisibility}
                  toggleLoginModalVisibility={toggleLoginModalVisibility}
                />
              </div>
            </div>
          )}
        </Col>
        
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          md={{ span: 14, order: 2 }}
          lg={{ span: 14, order: 2 }}
          style={{
            position: 'relative',
            overflow: 'visible',
            opacity: showVideo ? 1 : 0,
            transition: 'opacity 2s ease-in',
            height: isMobile ? 'calc(100vh - 200px)' : '70vh',
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-end',
            alignItems: isMobile ? 'center' : 'flex-end',
            margin: 0,
            padding: 0,
            marginTop: isMobile ? '-50px' : 0,  // Add this line
          }}
        >
          <div style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-end',
            alignItems: isMobile ? 'center' : 'flex-end',
            margin: 0,
            padding: 0,
          }}>
            {isMobile ? (
              <>
                <video
                  ref={firstVideo}
                  className="heroVideo"
                  muted
                  playsInline
                  preload="auto"
                  onEnded={handleFirstVideoEnd}
                  style={{
                    display: currentVideoIndex === 0 ? 'block' : 'none',
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <source src={babyVideo} type="video/mp4" />
                </video>

                <video
                  ref={secondVideo}
                  className="heroVideo"
                  muted
                  playsInline
                  preload="auto"
                  onEnded={handleSecondVideoEnd}
                  style={{
                    display: currentVideoIndex === 1 ? 'block' : 'none',
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <source src={cribMobileVideo} type="video/mp4" />
                </video>

                <video
                  ref={thirdVideo}
                  className="heroVideo"
                  muted
                  playsInline
                  preload="auto"
                  onEnded={handleThirdVideoEnd}
                  style={{
                    display: currentVideoIndex === 2 ? 'block' : 'none',
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <source src={babyWithToysVideo} type="video/mp4" />
                </video>

                <video
                  ref={fourthVideo}
                  className="heroVideo"
                  muted
                  playsInline
                  preload="auto"
                  onEnded={handleFourthVideoEnd}
                  style={{
                    display: currentVideoIndex === 3 ? 'block' : 'none',
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <source src={babyFootVideo} type="video/mp4" />
                </video>

                <div style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: 'linear-gradient(to bottom, white 0%, transparent 15%, transparent 85%, white 100%)',
                  zIndex: 2,
                  pointerEvents: 'none'
                }} />
              </>
            ) : (
              <>
                <div style={{ position: 'relative' }}>
                  <video
                    ref={firstVideo}
                    className="heroVideo"
                    muted
                    playsInline
                    preload="auto"
                    onEnded={handleFirstVideoEnd}
                    style={{
                      display: currentVideoIndex === 0 ? 'block' : 'none',
                      height: '100%',
                      width: 'auto',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      objectPosition: 'right bottom',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <source src={babyVideo} type="video/mp4" />
                  </video>
                  {currentVideoIndex === 0 && (
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      width: '200px',
                      background: 'linear-gradient(to right, white 0%, transparent 100%)',
                      zIndex: 2,
                      pointerEvents: 'none'
                    }} />
                  )}
                </div>

                <div style={{ position: 'relative' }}>
                  <video
                    ref={secondVideo}
                    className="heroVideo"
                    muted
                    playsInline
                    preload="auto"
                    onEnded={handleSecondVideoEnd}
                    style={{
                      display: currentVideoIndex === 1 ? 'block' : 'none',
                      height: '100%',
                      width: 'auto',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      objectPosition: 'right bottom',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <source src={cribMobileVideo} type="video/mp4" />
                  </video>
                  {currentVideoIndex === 1 && (
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      width: '200px',
                      background: 'linear-gradient(to right, white 0%, transparent 100%)',
                      zIndex: 2,
                      pointerEvents: 'none'
                    }} />
                  )}
                </div>

                <div style={{ position: 'relative' }}>
                  <video
                    ref={thirdVideo}
                    className="heroVideo"
                    muted
                    playsInline
                    preload="auto"
                    onEnded={handleThirdVideoEnd}
                    style={{
                      display: currentVideoIndex === 2 ? 'block' : 'none',
                      height: '100%',
                      width: 'auto',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      objectPosition: 'right bottom',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <source src={babyWithToysVideo} type="video/mp4" />
                  </video>
                  {currentVideoIndex === 2 && (
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      width: '200px',
                      background: 'linear-gradient(to right, white 0%, transparent 100%)',
                      zIndex: 2,
                      pointerEvents: 'none'
                    }} />
                  )}
                </div>

                <div style={{ position: 'relative' }}>
                  <video
                    ref={fourthVideo}
                    className="heroVideo"
                    muted
                    playsInline
                    preload="auto"
                    onEnded={handleFourthVideoEnd}
                    style={{
                      display: currentVideoIndex === 3 ? 'block' : 'none',
                      height: '100%',
                      width: 'auto',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      objectPosition: 'right bottom',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <source src={babyFootVideo} type="video/mp4" />
                  </video>
                  {currentVideoIndex === 3 && (
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      width: '200px',
                      background: 'linear-gradient(to right, white 0%, transparent 100%)',
                      zIndex: 2,
                      pointerEvents: 'none'
                    }} />
                  )}
                </div>
              </>
            )}
          </div>
        </Col>

        {isMobile && (
          <Col
            xs={{ span: 24, order: 3 }}
            sm={{ span: 24, order: 3 }}
            style={{
              padding: '20px',
              marginTop: '-150px',
              background: 'none',
              zIndex: 3,
            }}
          >
            <div
              className='welcomeCard'
              style={{
                background: 'rgba(250, 250, 250, 0.95)',
                padding: 25,
                borderRadius: 10,
                boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
                opacity: showGetStarted ? 1 : 0,
                transition: 'opacity 0.5s ease-in',
                marginBottom: 0,
                marginTop: "-100px"
              }}
            >
              <p style={{
                fontSize: '20px',
                color: '#666666',
                margin: '0',
                lineHeight: '1.4',
              }}>
                Get organized and confident with an app built for first-time parents.
              </p>
              <ActionButtons 
                toggleRegistrationModalVisibility={toggleRegistrationModalVisibility}
                toggleLoginModalVisibility={toggleLoginModalVisibility}
              />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}

Hero.propTypes = {
  toggleRegistrationModalVisibility: PropTypes.func.isRequired,
  toggleLoginModalVisibility: PropTypes.func.isRequired,
};

export default Hero;