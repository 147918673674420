import React from 'react';
import { connect } from 'react-redux';
import * as friendsFunctions from '../../../redux/friends/actions';

const FriendCards = ({
  friends = null,
  requestsSent = null,
  requestsReceived = null,
  removeFriend,
  rejectEmail,
  handleViewFriendList,
  acceptInvite,
  rejectInvite
}) => {
  return (
    <div className="w-full space-y-8">
      {/* Friends Section */}
      <div>
        <h2 className="text-2xl font-semibold mb-4">Friends</h2>
        {friends?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {friends.map(friend => (
              <div
                key={friend.uuid}
                className="bg-gray-50 rounded-xl p-6 space-y-4 border border-gray-100"
              >
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium">
                    {`${friend.first_name} ${friend.last_name}`}
                  </h3>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleViewFriendList(friend.uuid)}
                    className="px-6 py-2 bg-[#6B4EFF] text-white rounded-full hover:bg-[#5538EE] transition-colors duration-200 flex-1"
                  >
                    View List
                  </button>
                  <button
                    onClick={() => removeFriend(friend.uuid)}
                    className="px-6 py-2 text-gray-500 hover:text-red-600 rounded-full transition-colors duration-200 flex-1 border border-transparent hover:border-red-600"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-50 rounded-xl">
            <p className="text-gray-500">No friends added yet.</p>
          </div>
        )}
      </div>

      {/* Invited Section */}
      <div>
        <h2 className="text-2xl font-semibold mb-4">Invited</h2>
        {requestsSent?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {requestsSent.map(request => (
              <div
                key={request.uuid}
                className="bg-gray-50 rounded-xl p-6 space-y-4 border border-gray-100"
              >
                <div className="flex items-center justify-between">
                  <p className="text-gray-600 break-all font-medium">
                    {request.recipient_email}
                  </p>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={() => rejectEmail(request)}
                    className="px-6 py-2 text-gray-500 hover:text-red-600 rounded-full transition-colors duration-200 border border-transparent hover:border-red-600"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-50 rounded-xl">
            <p className="text-gray-500">No friends invited yet.</p>
          </div>
        )}
      </div>

      {/* Invited By Section */}
      <div>
        <h2 className="text-2xl font-semibold mb-4">Invited By</h2>
        {requestsReceived?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {requestsReceived.map(request => (
              <div
                key={request.uuid}
                className="bg-gray-50 rounded-xl p-6 space-y-4 border border-gray-100"
              >
                <div className="flex items-center justify-between">
                  <p className="text-gray-600 break-all font-medium">
                    {request.sender_email}
                  </p>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => acceptInvite(request.sender_email)}
                    className="px-6 py-2 bg-[#6B4EFF] text-white rounded-full hover:bg-[#5538EE] transition-colors duration-200 flex-1"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => rejectInvite(request)}
                    className="px-6 py-2 text-gray-500 hover:text-red-600 rounded-full transition-colors duration-200 flex-1 border border-transparent hover:border-red-600"
                  >
                    Reject
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-50 rounded-xl">
            <p className="text-gray-500">No pending invites.</p>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  friends: state.friends.friends,
  requestsSent: state.friends.requestsSent,
  requestsReceived: state.friends.requestsReceived
});

const mapDispatchToProps = (dispatch) => ({
  rejectEmail: (invite) => dispatch(friendsFunctions.rejectEmail(invite)),
  removeFriend: (uuid) => dispatch(friendsFunctions.removeFriend(uuid)),
  handleViewFriendList: (uuid) => dispatch(friendsFunctions.selectFriendList(uuid)),
  acceptInvite: (email) => dispatch(friendsFunctions.addNewFriend(email)),
  rejectInvite: (request) => dispatch(friendsFunctions.rejectEmail(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(FriendCards);