// src/app/components/LoadingSpinner.js
import React from 'react';
import { Loader2 } from 'lucide-react';

const LoadingSpinner = () => {
  const styles = {
    loadingContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.9)'
    },
    spinner: {
      width: '48px',
      height: '48px',
      color: '#3b82f6',
      animation: 'spin 1s linear infinite'
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    }
  };

  // Create a style tag for the keyframes animation
  const styleSheet = document.createElement('style');
  styleSheet.textContent = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  document.head.appendChild(styleSheet);

  return (
    <div style={styles.loadingContainer}>
      <div>
        <Loader2 style={styles.spinner} />
      </div>
    </div>
  );
};

export default LoadingSpinner;