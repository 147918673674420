const sampleFriendData = {
    uuid: "f123-456",
    first_name: "Sarah",
    last_name: "Chen",
    checkedCategories: {
      "cat-1": true,
      "cat-2": true,
      "cat-3": false,
      "cat-4": true,
      "cat-5": false,
      "cat-6": true,
      "cat-7": false,
      "cat-8": true,
      "cat-9": true,
      "cat-10": false,
      "cat-11": true,
      "cat-12": false,
      "cat-13": true,
      "cat-14": false,
      "cat-15": true,
      "cat-16": true,
      "cat-17": false,
      "cat-18": true,
      "cat-19": false,
      "cat-20": true
    },
    selectedProducts: {
      "cat-1": "product-a",
      "cat-2": "product-b",
      "cat-4": "product-c",
      "cat-6": "none",
      "cat-8": "product-d",
      "cat-9": "none",
      "cat-11": "product-e",
      "cat-13": "product-f",
      "cat-15": "none",
      "cat-16": "product-g",
      "cat-18": "product-h",
      "cat-20": "none"
    }
  };
  
  export default sampleFriendData;