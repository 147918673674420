import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button } from 'antd';
import productsBackground from '../../assets/login/products_background.png';

export default function ProductsSection({ toggleRegistrationModalVisibility }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: [0.3],
        rootMargin: '-50px 0px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Row
      ref={sectionRef}
      justify="center"
      data-section="products"
      style={{
        background: `url(${productsBackground}) no-repeat center bottom`,
        backgroundSize: 'cover',
        minHeight: '700px',
        height: '100vh',
        width: '100%',
        margin: 0,
        position: 'relative',
        display: 'flex',
        alignItems: isMobile ? 'center' : 'flex-start',
        paddingTop: isMobile ? '0' : '120px'
      }}
    >
      <Col
        xs={24}
        md={16}
        lg={12}
        style={{
          textAlign: 'center',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 1s ease-out, transform 1s ease-out',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <h2
          style={{
            fontSize: '32px',
            fontWeight: '600',
            color: '#1F1F1F',
            marginBottom: '16px',
            marginTop: isMobile ? '-100px' : '0px',
          }}
        >
          Modern problems require modern solutions.
        </h2>
        <p
          style={{
            fontSize: '20px',
            color: '#666666',
            lineHeight: '1.4',
            margin: '0',
            marginBottom: '32px'
          }}
        >
          When it comes to products, there's so much noise out there.
        </p>

        <Button
          onClick={toggleRegistrationModalVisibility}
          style={{
            background: '#6B4EFF',
            color: 'white',
            border: 'none',
            borderRadius: '20px',
            height: '40px',
            padding: '0 24px',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 1s ease-out, transform 1s ease-out'
          }}
        >
          Sign up
        </Button>
      </Col>
    </Row>
  );
}