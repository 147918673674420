import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import movingBackground from '../../assets/login/moving_background.png';

const ThankYouSection = ({ toggleRegistrationModalVisibility }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = React.useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: [0.3],
        rootMargin: '-50px 0px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  return (
    <div 
      ref={sectionRef}
      style={{
        position: 'relative',
        width: '100%',
        minHeight: isMobile ? '400px' : '700px',
        height: isMobile ? 'auto' : '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        margin: 0,
        padding: '50px 0',
        marginTop: isMobile ? '75px' : '0px',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${movingBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'linear-gradient(to bottom, white 0%, rgba(255,255,255,0) 90%)',
          pointerEvents: 'none'
        }}
      />
      
      <div style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '32px',
        zIndex: 1
      }}>
        <h2
          style={{
            position: 'relative',
            color: 'white',
            fontSize: isMobile ? '28px' : '32px',
            fontWeight: '600',
            padding: '16px 32px',
            margin: isMobile ? '0 50px' : 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            borderRadius: '8px',
            textAlign: 'center',
            maxWidth: '90%',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(-20px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
          }}
        >
          We're grateful to be on this journey with you.
        </h2>
        
        <Button
          onClick={toggleRegistrationModalVisibility}
          style={{
            background: '#6B4EFF',
            color: 'white',
            border: 'none',
            borderRadius: '20px',
            height: '40px',
            padding: '0 24px',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
            zIndex: 2
          }}
        >
          Sign up
        </Button>
      </div>
    </div>
  );
};

export default ThankYouSection;