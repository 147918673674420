import React, { Component } from 'react';
import Register from '../views/Register';
import FriendRequestRegister from '../views/FriendRequestRegister';
import ResetPassword from '../views/ResetPassword';
import Login from '../views/login';

import { Layout } from 'antd';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

const { Content } = Layout;

export default class LoginRouter extends Component {
  render() {
    return (
      <Router>
        <Layout>
          <Content style={styles.content}>
            <Switch>
              <Route exact path='/login' component={Login} />
              <Route exact path='/register/:token' component={Register} />
              <Route exact path='/friend_request_register/:token' component={FriendRequestRegister} />
              <Route exact path='/forgot_password/:token' component={ResetPassword} />
              <Redirect to='/login' />
            </Switch>
          </Content>
        </Layout>
      </Router>
    )
  }
}

const styles = {}
