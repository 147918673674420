import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import ProductChatbot from './productChatbot';

const ProductSelection = ({
  categoryId,
  categoryName,
  onProductSelect,
  onNoThanks,
  enhancedProducts
}) => {
  const [showProductList, setShowProductList] = useState(false);

  console.log('ProductSelection rendering:', { categoryId, categoryName });


  return (
    <div className="selection-container">
      {/* Chatbot Section */}
      <div className="chatbot-section">
        <ProductChatbot
          categoryId={categoryId}
          categoryName={categoryName}
          onProductSelect={onProductSelect}
          onNoThanks={onNoThanks}
        />
      </div>

      {/* Toggle Button */}
      <button
        className="toggle-button"
        onClick={() => setShowProductList(!showProductList)}
      >
        <div className="toggle-content">
          <span>{showProductList ? 'Hide' : 'Show'} all {categoryName}</span>
          {showProductList ? (
            <ChevronUp size={20} className="toggle-icon" />
          ) : (
            <ChevronDown size={20} className="toggle-icon" />
          )}
        </div>
      </button>

      {/* Product List Section */}
      {showProductList && (
        <div className="product-selection-prompt">
          <div className="product-list">
            {enhancedProducts.map((product) => (
              <div key={product.uuid} className="product-select-item">
                <span className="product-select-name">
                  {product.name || product.product_name}
                </span>
                <button
                  className="select-button"
                  onClick={() => onProductSelect(
                    categoryId,
                    product.uuid,
                    product.name || product.product_name
                  )}
                >
                  Select
                </button>
              </div>
            ))}
          </div>
          <button
            className="no-thanks-button"
            onClick={() => onNoThanks(categoryId)}
          >
            Skip this category
          </button>
        </div>
      )}

      <style jsx>{`
        .selection-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem 0;
          background-color: white;
          border-color: none;
        }

        .selection-container:focus,
        
        .selection-container:hover {
          outline: none;
          border-color: transparent;
        }

        .chatbot-section {
          border-radius: 0.5rem;
          overflow: hidden;
          background-color: white;
        }

        .toggle-button {
          background: none;
          border: none;
          padding: 0.75rem;
          width: 100%;
          cursor: pointer;
          transition: background-color 0.2s;
          border-radius: 0.375rem;
        }

        .toggle-content {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          color: #6b7280;
          font-size: 0.875rem;
        }

        .toggle-icon {
          transition: transform 0.2s;
        }

        .product-selection-prompt {
          background-color: white;
          border-radius: 0.5rem;
          padding: 1rem;
          animation: slideDown 0.3s ease-out;
        }

        .product-list {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          margin-bottom: 1rem;
        }

        .product-select-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem;
          border: 1px solid #e5e7eb;
          border-radius: 0.375rem;
          background-color: white;
          transition: all 0.2s;
        }

        .product-select-item:hover {
          border-color: #d1d5db;
          background-color: #f9fafb;
        }

        .product-select-name {
          font-size: 0.875rem;
          color: #374151;
        }

        .select-button {
          padding: 0.5rem 1rem;
          background-color: #2563eb;
          color: white;
          border: none;
          border-radius: 0.375rem;
          font-size: 0.875rem;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .select-button:hover {
          background-color: #1d4ed8;
        }

        .no-thanks-button {
          width: 100%;
          padding: 0.75rem;
          background: none;
          border: 1px solid #e5e7eb;
          border-radius: 0.375rem;
          color: #6b7280;
          font-size: 0.875rem;
          cursor: pointer;
          transition: all 0.2s;
        }

        .no-thanks-button:hover {
          background-color: #f9fafb;
          border-color: #d1d5db;
          color: #374151;
        }

        @keyframes slideDown {
          from {
            opacity: 0;
            transform: translateY(-10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default ProductSelection;
